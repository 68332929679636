import React, { useState, useEffect, useRef } from 'react';
import { useGlobalSelectedId, onSetActiveIndicator } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';
import Colors from '../utilities/Colors';
import ListMeta from './ListMeta';

export interface ListChartProps {
  options: any;
  data: any;
  width: number;
  height: number;
  colorClass: string;
}

const MARGIN_X = 0;
const MARGIN_Y = 15;

const List: React.FC<ListChartProps> = ({ options, data, width, height, colorClass }) => {
  const [preppedData, setPreppedData] = useState<any>([]);
  const [hoveredIndicator, setHoveredIndicator] = useState<any>(null);
  const [listHeight, setListHeight] = useState<number>(27.5); // Set a fixed height for each list
  const selectedId = useGlobalSelectedId();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({ width, height });

  // Calculate a consistent max value across all data points
  const globalMaxValue = Math.max(
    ...Object.values(data.dataJSON).flatMap((d: any) =>
      options.variables.map((v: any) => {
        const value = d[v.variable];
        return isNaN(value) ? 0 : value;
      })
    )
  );

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        if (!entries || entries.length === 0) return;
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (selectedId) {
      prepData();
    }
  }, [selectedId]);

  const prepData = () => {
    if (selectedId) {
      const _filteredData = options.variables.filter((v: any) => !v.sort.Tooltip_only);
      const _preppedData = _filteredData
        .map((v: any) => {
          const rawValue = data.dataJSON[selectedId][v.variable];
          const isNA = rawValue === undefined || rawValue === null || rawValue === "NA";
          return {
            name: v.label,
            value: isNA ? 'N/A' : rawValue,
            variable: v.variable,
            avg: data.meta.avg[v.variable],
            index: data.keyIndexDataArray[v.variable] ? data.keyIndexDataArray[v.variable][selectedId].index : 0,
            na: data.meta.na_count[v.variable],
            len: data.dataArray[v.variable] ? data.dataArray[v.variable].length : 0,
          };
        })
        .sort((a: any, b: any) => (b.value !== 'N/A' ? b.value : 0) - (a.value !== 'N/A' ? a.value : 0)); // Sort descending by value

      setPreppedData(_preppedData);
    }
  };

  // Adjust the list scale to use the global max value
  const listScale = (value: number) => {
    const maxWidth = dimensions.width - (2 * MARGIN_X);
    return ((value / globalMaxValue) * maxWidth) / 2.5;
  };

  const onClicked = (variable: string) => {
    onSetActiveIndicator(variable);
  };

  const onHovered = (variable: any) => {
    const hoveredVar = options.variables.find((v: any) => v.variable === variable);
    setHoveredIndicator(hoveredVar);
  };

  const onLeave = () => {
    // Uncomment to reset to first listed indicator on mouse leave
    // setHoveredIndicator(null);
  };

  const fontSize = 12;
  const fontSize2 = 12;
  const labelWidth = 250;
  const chartWidth = `calc(100% - ${labelWidth * 2}px)`;

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'flex-start', paddingRight: "16px" }}>
      <div style={{
          marginLeft: '0px', 
          marginRight: '0px', 
          fontSize: fontSize2, 
          lineHeight: `${fontSize2 + 2 }px`,
          width: "250px" 
        }}>
        <ListMeta
          dataArray={data.dataArray[hoveredIndicator ? hoveredIndicator : options.variables[0]]}
          data={data}
          indicator={hoveredIndicator ? hoveredIndicator : options.variables[0]}
        />
      </div>
      <div style={{
          marginLeft: '0px',
          marginRight: '0px',
          fontSize: fontSize2,
          lineHeight: `${fontSize2 + 2}px`,
          width: `${labelWidth}px`,
        }}
      >
        {preppedData.map((d: any, i: number) => (
          <div key={`info-${i}`}
            style={{
              fontSize: fontSize2,
              lineHeight: 0.95,
              display: "grid",
              justifyContent: "space-between",
              height: `${listHeight}px`,
              marginRight: "15px",
            }}
          >
            <div style={{ fontWeight: 'normal', width: "100%", alignContent: "center", paddingRight: "0px", display: "inline-flex" }}>
              <div style={{ width: "20px" }}>{`${i + 1}.`}&nbsp;&nbsp;</div>
              <div>{`${d.name.split("(")[0].replace("Industry Category: ", "").replace("Occupational Category: ", "").replace(" among adults aged >=18 years", "").replace(" among adults ages >=18 years", "")}`}</div>
            </div>
          </div>
        ))}
      </div>
      <div style={{
          fontSize,
          lineHeight: `${fontSize + 2}px`,
          display: 'flex',
          width: chartWidth,
          position: "relative"
        }}
      >
        <svg width="100%" height={listHeight * preppedData.length} style={{ display: "inline-block" }}>
          <g transform={`translate(${MARGIN_X}, 0)`}>
            {preppedData.map((d: any, i: number) => (
              <g key={`list-${i}`} transform={`translate(0, ${i * listHeight})`}>
                <rect
                  x={0}
                  y={0}
                  width={d.value === 'N/A' ? 0 : listScale(d.value)} // Handle N/A by setting width to 0
                  height={listHeight - 2}
                  fill={Colors.getColorQuintile(d.index, d.na, d.len, `${colorClass}_variant`)}
                  onClick={() => onClicked(d.variable)}
                  onMouseEnter={() => onHovered(d.variable)}
                  onMouseLeave={onLeave}
                  style={{ cursor: 'pointer', transition: 'width 0.5s ease, fill 0.5s ease' }}
                />
                <line
                  x1={listScale(d.avg)}
                  y1={0}
                  x2={listScale(d.avg)}
                  y2={listHeight - 2}
                  stroke="#9E9C9C"
                  strokeWidth={1}
                  strokeDasharray="4"
                />
                <text
                  x={5}
                  y={listHeight / 2}
                  alignmentBaseline="middle"
                  fill={Colors.processBlack}
                  fontSize={fontSize}
                  pointerEvents="none"
                >
                  {d.value === 'N/A' ? 'N/A' : d2gRound(d.value, { DESCRIPTOR: data.meta["DESCRIPTOR"][d.variable] })}
                </text>
              </g>
            ))}
          </g>
        </svg>
      </div>
    </div>
  );
};

export default List;
