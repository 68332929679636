import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation
} from 'react-router-dom';
import { DataProvider } from './data/DataContext';
import StatExplorer from './views/StatExplorer';
import Dashboards from './views/Dashboards';
import Comparisons from './views/Comparisons';
import MoALogo from './assets/icons/MoALogo.svg';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useGlobalSelectedId, useStatusStoreNavigation } from './data/StatusStore'; // Correct import path
import SearchPopup from './components/structure/SearchPopup'; // Correct import path
import { useData, FlatFileCsvData} from './data/DataContext';
import { useGlobalActiveIndicator, useGlobalBivariateIndicator, useGlobalActiveGeography, onClickHistogram } from './data/StatusStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface NavigationProps {
  indicatorOptions: any | null,
  groupedModules: any | null,
  groupedModulesDashboard: any | null,
  allData: any | null,
  setAllData: (data: any) => void,
  setGroupedModules: (data: any) => void,
  setGroupedModulesDashboard: (data: any) => void,
  setIndicatorOptions: (data: any) => void,
  searchData: any,
  setSearchData: (data: any) => void,
  setModuleIdIncrement: Function,
  moduleIdIncrement: number,
  toggleMenu: Function,
}

function Navigation({ indicatorOptions, groupedModules, groupedModulesDashboard, allData, setAllData, searchData, 
  setSearchData, setIndicatorOptions, setGroupedModules, setGroupedModulesDashboard,
  setModuleIdIncrement, moduleIdIncrement, toggleMenu, 
}: NavigationProps) {
  const navigate = useNavigate();
  const location = useLocation();
  
  // Initialize the StatusStore with navigation and location
  useStatusStoreNavigation();

  // Extract page from URL search parameters
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get('page') || 'explorer';
  const { 
    dataActive, dataTract, dataNTA, dataPUMA, dataNYC, dataCD, dataBoro, isLoadingSorter, dataSorterOrder, dataSorterOrderDashboard,
    isLoadingTract, isLoadingNTA, isLoadingPUMA, isLoadingNYC, isLoadingCD, isLoadingBoro, isLoadingAllTabularData, dataSorter, dataSorterDashboard,
    error } = useData();

  const activeGeography = useGlobalActiveGeography();
  const selectedId = useGlobalSelectedId();
  ////

  const [childrenReady, setChildrenReady] = useState(0);
  const [locked, setLocked] = useState<boolean>(false);
  const totalChildren = window.location.hostname === "localhost" ? 2 : 1;

  const handleChildReady = () => {
    console.log("101124 handleChildReady ");
    setChildrenReady((prev) => prev + 1);
  };

  useEffect(() => {
    console.log("101124 child ready childrenReady", childrenReady);
    console.log("101124 child ready totalChildren", totalChildren);
    if (childrenReady === totalChildren) {
      // All child components have rendered
      console.log("101124 child ready selectedId", selectedId)
      const searchSelectedId = searchParams.get('selectedId') || '101';
      console.log("101124 child ready searchSelectedId", searchSelectedId)
      onClickHistogram(searchSelectedId)
    }
  }, [childrenReady]);

  ////
  useEffect(() => {
    console.log("X102724 groupedModules",groupedModules);
  }, [groupedModules]);
  useEffect(() => {
    console.log("X102724 isLoadingAllTabularData",isLoadingAllTabularData);
  }, [isLoadingAllTabularData]);

  // Log the current page for debugging
  useEffect(() => {
    console.log("Current page:", currentPage);
  }, [currentPage]);

  useEffect(() => {
    console.log("C092524 allData", allData);
  }, [allData]);
  useEffect(() => {
    console.log("X102724 dataActive", dataActive);
  }, [dataActive]);

  function cleanString(input: string): string {
    let returnable = input
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/[^a-z0-9-]/g, ''); // Remove all non-web-safe characters except hyphens
    console.log("C102724 cleanString", input,">", returnable);
    return returnable;
  }

  useEffect(() => {
    //if (!isLoadingBoro && !isLoadingCD && !isLoadingNYC && !isLoadingPUMA && !isLoadingTract) {
    if (!isLoadingAllTabularData) {
        if (dataBoro && dataCD && dataNYC && dataPUMA && dataTract && dataNTA) {
            console.log("C100524 dataBoro", dataBoro);
            console.log("dataCD", dataCD);
            console.log("dataNYC", dataNYC);
            console.log("dataPUMA", dataPUMA);
            console.log("101924 dataTract", dataTract);
            console.log("B100524 dataNTA", dataNTA);
            let _indicatorOptions = [] as any;
            let _excludeOptions = ["", "DISPLAY_NAME"] as any;
            let testLimit = 0; 
            Object.keys(dataTract.meta.VARIABLE_NAME).forEach((i: string) => {
                //if (testLimit < 3){
                    //if (!_excludeOptions.includes(dataTract.meta.DISPLAY_NAME[i])){
                        _indicatorOptions.push(
                            { label: dataTract.meta.DISPLAY_NAME[i], value: i, type:"swarm" }
                        )
                    //    _indicatorOptions.push(
                    //        { label: dataTract.meta.DISPLAY_NAME[i], value: i, type:"histogram" }
                    //    )
                    //    testLimit++;
                    //}
                //}
                
            })
            setAllData({
                "Boro": dataBoro,
                "CD": dataCD,
                "NYC": dataNYC,
                "PUMA": dataPUMA,
                "Tract": dataTract,
                "NTA": dataNTA,
            });
            console.log("101924 _indicatorOptions", _indicatorOptions)
            setIndicatorOptions(_indicatorOptions)
        }
    }
}, [/*dataBoro, isLoadingBoro, dataCD, isLoadingCD, dataNYC, isLoadingNYC, dataPUMA, isLoadingPUMA, dataTract, isLoadingTract,*/ isLoadingAllTabularData]);
  function disambiguateModuleId(moduleId:number, data:any, used:any){
    return moduleId;
    /*let _moduleId:string = moduleId.toString();
    let returnable = _moduleId;
    if (used.includes(_moduleId)){
      if (used.includes(_moduleId + "a")){
        if (used.includes(_moduleId + "b")){
          if (used.includes(_moduleId + "c")){
            returnable= _moduleId + "d";
          }else{
            returnable = _moduleId + "c";
          }
        }else{
          returnable = _moduleId + "b";
        }
      }else{
        returnable = moduleId + "a";
      }
    }else{
      returnable = _moduleId
    }
    console.log("DD101424 moduleId", _moduleId,">", returnable);
    //console.log("DD101424 data", data);
    console.log("DD101424 used", used);
    return returnable;*/
  }
  useEffect(() => {
    console.log("100724 dataActive", dataActive);
    console.log("100724 allData", allData);
    console.log("092924 isLoadingAllTabularData, isLoadingBoro, isLoadingCD, isLoadingNYC, isLoadingPUMA, isLoadingTract, isLoadingSorter", 
      isLoadingAllTabularData, isLoadingBoro, isLoadingCD, isLoadingNYC, isLoadingPUMA, isLoadingTract, isLoadingNTA, isLoadingSorter);
    console.log("092924 dataActive, dataSorter, Object.keys(dataActive).length > 0", dataActive, dataSorter, Object.keys(dataActive ? dataActive : {}).length > 0);
    let moduleId = 1;
    let usedModuleIds:any = [];
    if (!isLoadingAllTabularData) {  
      if (dataActive && dataSorter && Object.keys(dataActive).length > 0) {
        if (!locked){
          //////////////////////////////////////////////////////////////////////
          // STAT EXPLORER
          //////////////////////////////////////////////////////////////////////
          let _indicatorOptions = [] as any;
          
          const groupedData: any = {};
          const moduleData: any = {};
          const preFlattenedModules: any = {};
          
          dataSorter.forEach((_data:any) => {
            const data = { ..._data };
            if (data.Module === "Point Data"){
              return;
            }
            console.log("B101924 data", data);
            let category = null;
            let subCategory = "";
            const module = data.Module || "Unknown";
            const shape = data.Shape || "Unknown";
            let sumTo100 = data["Sums to 100"] 
                ? data["Sums to 100"] !== "Ungrouped" 
                    ? data["Sums to 100"]
                    : data["Sums to 100"] 
                : "Ungrouped";
            sumTo100 = shape.toLowerCase() === "pyramid" ? "Ungrouped" : sumTo100;
            data["moduleId"] = `${moduleId}a`; //disambiguateModuleId(moduleId, data, usedModuleIds);
            console.log("B102524 category,subCategory,module)", category,subCategory,module)
            console.log("B102524 dataSorterOrder", dataSorterOrder)

            moduleId++;
            if (!preFlattenedModules[module]) {
              preFlattenedModules[module] = {};
            }
        
            if (!preFlattenedModules[module][sumTo100]) {
              preFlattenedModules[module][sumTo100] = {};
            }
            if (!preFlattenedModules[module][sumTo100][shape]) {
              preFlattenedModules[module][sumTo100][shape] = [];
            }
            preFlattenedModules[module][sumTo100][shape].push(data);
            moduleData[data["Module"]] = data;
          });
          let _searchData: any = {
            // 'search string': { directive: "what to do with this when clicked", data: "Some data about the search string" },
            //'Cholesterol screening': [{ directive: "explorer module", data: "cholesterol_screening_cdc", directive_data: "174a" },
          };
          console.log("B101924 _searchData", _searchData);
          console.log("D102824 groupedData", groupedData);
          console.log("dataActive", dataActive);
          //console.log("L092924 dataActive.meta.VARIABLE_NAME", dataActive.meta.VARIABLE_NAME);
          //console.log("L092924 dataActive.meta", dataActive.meta);
          console.log("102924 groupedData", groupedData);
          const flattenedModules: any = preFlattenedModules;

          console.log("103024 preFlattenedModules", preFlattenedModules);
          console.log("103024 flattenedModules", flattenedModules);
          console.log("103024 groupedData:", groupedData);
          console.log("102724 moduleData", moduleData);
          console.log("102724 dataSorterOrder", dataSorterOrder);

          let lastCategory = "";
          let makeHeader = false;
          Object.keys(dataSorterOrder).forEach((category) => {
            if (category !== lastCategory){
              console.log("F102824 category", category);
              lastCategory = category;
              makeHeader = true;
            }
            Object.keys(dataSorterOrder[category]).forEach((subCategory) => {
              Object.keys(dataSorterOrder[category][subCategory]).forEach((module) => {
                console.log("G102624 module", module);
                const uniqueModuleId = module;//dataSorterOrder[category][subCategory][module]["Unique Module ID"]
                const currentModuleId = dataSorterOrder[category][subCategory][module]["Current Module ID"]
                const _order = dataSorterOrder[category][subCategory][module]["Order of appearance"]
                const _sort = dataSorterOrder[category][subCategory][module]
                console.log("GGG102624 uniqueModuleId", uniqueModuleId);
                console.log("GGG102624 currentModuleId", currentModuleId);
                console.log("K102624 +++++++++++++++ _order", _order);
                let _flatModuleData = {} as any;
                if (flattenedModules[currentModuleId]){
                  _flatModuleData = JSON.parse(JSON.stringify(flattenedModules[currentModuleId]));
                  if (Object.keys(_flatModuleData).length > 0){
                    console.log("K102624 _flatModuleData",currentModuleId, _flatModuleData);
                    Object.keys(_flatModuleData).forEach((shape) => {
                      console.log("K102624 ---------------",shape, _flatModuleData[shape]);
                      Object.keys(_flatModuleData[shape]).forEach((grouping) => {
                        console.log("K102624 ---------------============",grouping, _flatModuleData[shape][grouping]);
                        _flatModuleData[shape][grouping] = _flatModuleData[shape][grouping].map((variable:any) => {
                          console.log("K102624 ---------------============!!!!!!!!!!",variable);
                          _sort["Category_"] = category;
                          _sort["SubCategory_"] = subCategory;
                          const variableCopy = { ...variable };
                          variableCopy.Order = _order;
                          variableCopy.Sort = _sort;
                          variableCopy.moduleId = cleanString(uniqueModuleId);
                          return variableCopy;
                        });
                      });
                    });
                    
                  }else{
                    console.log("K102624 MISSING ------------------------------------");
                    console.log("K102624 uniqueModuleId", uniqueModuleId);
                    console.log("K102624 currentModuleId", currentModuleId);
                    console.log("K102624 --------------------------------------------");
                  }
                }else{
                  console.log("K102624 MISSING ------------------------------------");
                  console.log("K102624 uniqueModuleId", uniqueModuleId);
                  console.log("K102624 currentModuleId", currentModuleId);
                  console.log("K102624 --------------------------------------------");
                }
                console.log("F102824 _flatModuleData ~~~~~~~~~~~~~",currentModuleId, _flatModuleData);
                if (makeHeader){
                  makeHeader = false;
                  console.log("G102824 makeHeader category",category);
                  console.log("G102824 makeHeader _flatModuleData",_flatModuleData);
                  let COPIED_flatModuleData = JSON.parse(JSON.stringify(_flatModuleData));
                  let NEW_flatModuleData:any = {};
                  console.log("G102824 makeHeader COPIED_flatModuleData",COPIED_flatModuleData);
                  if (Object.keys(COPIED_flatModuleData).length > 0) {
                    let COPY_COPY = [JSON.parse(JSON.stringify(COPIED_flatModuleData[Object.keys(COPIED_flatModuleData)[0]][Object.keys(COPIED_flatModuleData[Object.keys(COPIED_flatModuleData)[0]])[0]]))[0]];
                    COPY_COPY[0].Shape = "category header";
                    COPY_COPY[0].Order = "0";
                    COPY_COPY[0].Tract = "X";
                    COPY_COPY[0].CD = "X";
                    COPY_COPY[0].PUMA = "X";
                    COPY_COPY[0].NYC = "X";
                    COPY_COPY[0].POINT = "X";
                    COPY_COPY[0].Boro = "X";

                    NEW_flatModuleData["Ungrouped"] = {"category header" : COPY_COPY};
                  }
                  console.log("G102824 makeHeader NEW_flatModuleData",NEW_flatModuleData);
                  dataSorterOrder[category][subCategory][`${cleanString(category)}-header`] = NEW_flatModuleData;
                }
                dataSorterOrder[category][subCategory][uniqueModuleId] = _flatModuleData;
              });
            });
          });
          console.log("G102824 dataSorterOrder", dataSorterOrder);
          console.log("D102524 groupedData", groupedData);
          //console.log("D102524 _indicatorOptions", _indicatorOptions);
          const processArraysInJSON = (obj: any, callback: (arr: any[]) => void): void => {
            for (const key in obj) {
              if (Array.isArray(obj[key])) {
                // If the property is an array, execute the callback function on it
                callback(obj[key]);
              } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                // If the property is an object, recursively process it
                processArraysInJSON(obj[key], callback);
              }
            }
          };
      
          // Call the function on `dataSorterOrder` with a callback to log each array
          processArraysInJSON(dataSorterOrder, (arr) => {
            //console.log("110324 Array found:", arr);
            arr.forEach(item => {
              //console.log("110324 Array item:", item);
              _searchData[item["DISPLAY_NAME"]] 
                  ? _searchData[`${item.Sort.SubCategory_} - ${item["DISPLAY_NAME"]}`].push({ directive: "explorer module", data: item["VARIABLE_NAME"], directive_data: item["moduleId"] })
                  : _searchData[`${item.Sort.SubCategory_} - ${item["DISPLAY_NAME"]}`] = [{ directive: "explorer module", data: item["VARIABLE_NAME"], directive_data: item["moduleId"] }]
            });
          });

          console.log("F092524 _indicatorOptions", _indicatorOptions);
          console.log("YYY102724 groupedData", groupedData);
          console.log("110324 _searchData",_searchData);
          setSearchData(_searchData);
          setIndicatorOptions(_indicatorOptions)
          //setGroupedModules(groupedData);
          
          console.log("Z102724 groupedData", groupedData);
          setGroupedModules({...dataSorterOrder});

          //////////////////////////////////////////////////////////////////////
          // DASHBOARDS
          //////////////////////////////////////////////////////////////////////

          let _indicatorOptionsDashboard = [] as any;
          
          const groupedDataDashboard: any = {};
          const moduleDataDashboard: any = {};
          const preFlattenedModulesDashboard: any = {};
          
          dataSorterDashboard.forEach((_data:any) => {
            const data = { ..._data };
            if (data.Module === "Point Data"){
              return;
            }
            console.log("B101924 data", data);
            let category = null;
            let subCategory = "";
            const module = data.Module || "Unknown";
            const shape = data.Shape || "Unknown";
            let sumTo100 = data["Sums to 100"] 
                ? data["Sums to 100"] !== "Ungrouped" 
                    ? data["Sums to 100"]
                    : data["Sums to 100"] 
                : "Ungrouped";
            sumTo100 = shape.toLowerCase() === "pyramid" ? "Ungrouped" : sumTo100;
            data["moduleId"] = `${moduleId}a`; //disambiguateModuleId(moduleId, data, usedModuleIds);
            console.log("B102524 category,subCategory,module)", category,subCategory,module)
            console.log("B102524 dataSorterOrderDashboard", dataSorterOrderDashboard)

            moduleId++;
            if (!preFlattenedModulesDashboard[module]) {
              preFlattenedModulesDashboard[module] = {};
            }
        
            if (!preFlattenedModulesDashboard[module][sumTo100]) {
              preFlattenedModulesDashboard[module][sumTo100] = {};
            }
            if (!preFlattenedModulesDashboard[module][sumTo100][shape]) {
              preFlattenedModulesDashboard[module][sumTo100][shape] = [];
            }
            preFlattenedModulesDashboard[module][sumTo100][shape].push(data);
            moduleDataDashboard[data["Module"]] = data;
          });
          let _searchDataDashboard: any = {
            // 'search string': { directive: "what to do with this when clicked", data: "Some data about the search string" },
            //'Cholesterol screening': [{ directive: "explorer module", data: "cholesterol_screening_cdc", directive_data: "174a" },
          };
          console.log("B101924 _searchDataDashboard", _searchDataDashboard);
          console.log("D102824 groupedDataDashboard", groupedDataDashboard);
          console.log("dataActive", dataActive);
          //console.log("L092924 dataActive.meta.VARIABLE_NAME", dataActive.meta.VARIABLE_NAME);
          //console.log("L092924 dataActive.meta", dataActive.meta);
          console.log("102924 groupedDataDashboard", groupedDataDashboard);
          const flattenedModulesDashboard: any = preFlattenedModulesDashboard;

          console.log("103024 preFlattenedModulesDashboard", preFlattenedModulesDashboard);
          console.log("103024 flattenedModulesDashboard", flattenedModulesDashboard);
          console.log("103024 groupedDataDashboard:", groupedDataDashboard);
          console.log("102724 moduleDataDashboard", moduleDataDashboard);
          console.log("102724 dataSorterOrderDashboard", dataSorterOrderDashboard);

          let lastCategoryDashboard = "";
          let makeHeaderDashboard = false;
          Object.keys(dataSorterOrderDashboard).forEach((category) => {
            if (category !== lastCategoryDashboard){
              console.log("F102824 category", category);
              lastCategoryDashboard = category;
              makeHeaderDashboard = true;
            }
            console.log("D110424 dataSorterOrderDashboard", dataSorterOrderDashboard);
            Object.keys(dataSorterOrderDashboard[category]).forEach((subCategory) => {
              Object.keys(dataSorterOrderDashboard[category][subCategory]).forEach((module) => {
                console.log("D110424 module", module);
                const uniqueModuleId = module;//dataSorterOrderDashboard[category][subCategory][module]["Unique Module ID"]
                const currentModuleId = dataSorterOrderDashboard[category][subCategory][module]["Current Module ID"]
                const _order = dataSorterOrderDashboard[category][subCategory][module]["Order of appearance"]
                const _sort = dataSorterOrderDashboard[category][subCategory][module]
                console.log("GGG102624 uniqueModuleId", uniqueModuleId);
                console.log("D110424 currentModuleId", currentModuleId);
                console.log("D110424 flattenedModulesDashboard", flattenedModulesDashboard);
                console.log("K102624 +++++++++++++++ _order", _order);
                let _flatModuleDataDashboard = {} as any;
                if (flattenedModulesDashboard[currentModuleId]){
                  _flatModuleDataDashboard = JSON.parse(JSON.stringify(flattenedModulesDashboard[currentModuleId]));
                  if (Object.keys(_flatModuleDataDashboard).length > 0){
                    console.log("K102624 _flatModuleData",currentModuleId, _flatModuleDataDashboard);
                    Object.keys(_flatModuleDataDashboard).forEach((shape) => {
                      console.log("K102624 ---------------",shape, _flatModuleDataDashboard[shape]);
                      Object.keys(_flatModuleDataDashboard[shape]).forEach((grouping) => {
                        console.log("K102624 ---------------============",grouping, _flatModuleDataDashboard[shape][grouping]);
                        _flatModuleDataDashboard[shape][grouping] = _flatModuleDataDashboard[shape][grouping].map((variable:any) => {
                          console.log("K102624 ---------------============!!!!!!!!!!",variable);
                          _sort["Category_"] = category;
                          _sort["SubCategory_"] = subCategory;
                          const variableCopyDashboard = { ...variable };
                          variableCopyDashboard.Order = _order;
                          variableCopyDashboard.Sort = _sort;
                          variableCopyDashboard.moduleId = cleanString(uniqueModuleId);
                          return variableCopyDashboard;
                        });
                      });
                    });
                    
                  }else{
                    console.log("K102624 MISSING ------------------------------------");
                    console.log("K102624 uniqueModuleId", uniqueModuleId);
                    console.log("K102624 currentModuleId", currentModuleId);
                    console.log("K102624 --------------------------------------------");
                  }
                }else{
                  console.log("K102624 MISSING ------------------------------------");
                  console.log("K102624 uniqueModuleId", uniqueModuleId);
                  console.log("K102624 currentModuleId", currentModuleId);
                  console.log("K102624 --------------------------------------------");
                }
                console.log("D110424 _flatModuleData ~~~~~~~~~~~~~",currentModuleId, _flatModuleDataDashboard);
                if (makeHeaderDashboard){
                  makeHeaderDashboard = false;
                  console.log("D110424 makeHeader category",category);
                  console.log("D110424 makeHeader _flatModuleDataDashboard",_flatModuleDataDashboard);
                  let COPIED_flatModuleDataDashboard = JSON.parse(JSON.stringify(_flatModuleDataDashboard));
                  let NEW_flatModuleDataDashboard:any = {};
                  console.log("D110424 makeHeader COPIED_flatModuleDataDashboard",COPIED_flatModuleDataDashboard);
                  let COPY_COPYDashboard = [JSON.parse(JSON.stringify(COPIED_flatModuleDataDashboard[Object.keys(COPIED_flatModuleDataDashboard)[0]][Object.keys(COPIED_flatModuleDataDashboard[Object.keys(COPIED_flatModuleDataDashboard)[0]])[0]]))[0]];
                  COPY_COPYDashboard[0].Shape = "category header";
                  COPY_COPYDashboard[0].Order = "0";
                  COPY_COPYDashboard[0].Tract = "X";
                  COPY_COPYDashboard[0].CD = "X";
                  COPY_COPYDashboard[0].PUMA = "X";
                  COPY_COPYDashboard[0].NYC = "X";
                  COPY_COPYDashboard[0].POINT = "X";
                  COPY_COPYDashboard[0].Boro = "X";

                  NEW_flatModuleDataDashboard["Ungrouped"] = {"category header" : COPY_COPYDashboard};
                  console.log("D110424 makeHeader NEW_flatModuleData",NEW_flatModuleDataDashboard);
                  dataSorterOrderDashboard[category][subCategory][`${cleanString(category)}-header`] = NEW_flatModuleDataDashboard;
                }
                dataSorterOrderDashboard[category][subCategory][uniqueModuleId] = _flatModuleDataDashboard;
              });
            });
          });
          console.log("G102824 dataSorterOrderDashboard", dataSorterOrderDashboard);
          console.log("D102524 groupedDataDashboard", groupedDataDashboard);
          //console.log("D102524 _indicatorOptions", _indicatorOptions);
          const processArraysInJSONDashboard = (obj: any, callback: (arr: any[]) => void): void => {
            for (const key in obj) {
              if (Array.isArray(obj[key])) {
                // If the property is an array, execute the callback function on it
                callback(obj[key]);
              } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                // If the property is an object, recursively process it
                processArraysInJSONDashboard(obj[key], callback);
              }
            }
          };
      
          // Call the function on `dataSorterOrderDashboard` with a callback to log each array
          processArraysInJSONDashboard(dataSorterOrderDashboard, (arr) => {
            console.log("110324 Array found:", arr);
            arr.forEach(item => {
              console.log("110324 Array item:", item);
              _searchDataDashboard[item["DISPLAY_NAME"]] 
                  ? _searchDataDashboard[`${item.Sort.SubCategory_} - ${item["DISPLAY_NAME"]}`].push({ directive: "explorer module", data: item["VARIABLE_NAME"], directive_data: item["moduleId"] })
                  : _searchDataDashboard[`${item.Sort.SubCategory_} - ${item["DISPLAY_NAME"]}`] = [{ directive: "explorer module", data: item["VARIABLE_NAME"], directive_data: item["moduleId"] }]
            });
          });

          console.log("F092524 _indicatorOptionsDashboard", _indicatorOptionsDashboard);
          console.log("YYY102724 groupedDataDashboard", groupedDataDashboard);
          console.log("110324 _searchDataDashboard",_searchDataDashboard);
          //DB TODO setSearchData(_searchDataDashboard);
          //DB TODO? setIndicatorOptions(_indicatorOptionsDashboard)
          //setGroupedModules(groupedData);
          
          setLocked(true);
          console.log("Z102724 groupedDataDashboard", groupedDataDashboard);
          setGroupedModulesDashboard({...dataSorterOrderDashboard});
        }
      }
    }
  }, [dataActive, isLoadingAllTabularData]);

  useEffect(() => {
    console.log("L092924 groupedModules",groupedModules);
  }, [groupedModules]);
  // Function to change page and preserve other parameters in order
  const changePage = (newPage: string) => {
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set('page', newPage); // Update the 'page' parameter

    // Construct the URL with parameters in the correct order
    const paramsOrder = ['page', 'lat', 'lng', 'zoom', 'selectedId', 'activeGeography', 'activeIndicator', 'bivariateIndicator'];
    const orderedParams = new URLSearchParams();
    
    paramsOrder.forEach(param => {
      const value = newSearchParams.get(param);
      if (value) orderedParams.set(param, value);
    });

    navigate(`/?${orderedParams.toString()}`, { replace: true });
    toggleMenu(); // Close the menu
  };

  return (
    <>
      <div className="top-navbar">
        <div className="menu-toggle" onClick={() => changePage('menu')}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <img src={MoALogo} alt="Measure of America" className="moa-logo" />
        <div className="navbar-title">NYC<b>DATA</b>2<b>GO</b></div>
        <div className="magnify-search-button" onClick={() => changePage('search')}>
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18.3337" cy="11.6667" r="9.91667" fill="none" stroke="black" strokeWidth="3.5" />
            <path d="M2 28.0001L11.3333 18.6667" stroke="black" strokeWidth="3.5" strokeLinecap="round" />
          </svg>
        </div>
      </div>
      <div className="sidebar">
        <ul className="nav-links">
          <li className="nav-links-li" onClick={() => changePage('explorer')}>STAT EXPLORER</li>
          <li className="nav-links-li" onClick={() => changePage('dashboard')}>DASHBOARDS</li>
          <li className="nav-links-li" onClick={() => changePage('compare')}>COMPARISONS</li>
        </ul>
      </div>
      <Routes>
        <Route path="/" element={currentPage === 'explorer' 
        ? <StatExplorer
            key={`Stat-Explorer-${activeGeography}`}
            handleChildReady={handleChildReady}
            indicatorOptions={indicatorOptions}
            groupedModules={groupedModules}
            activeGeography={activeGeography}
            allData={allData}
            moduleIdIncrement={moduleIdIncrement}
        /> 
        : currentPage === 'dashboard' 
        ? <Dashboards
            key={`Stat-Explorer-${activeGeography}`}
            handleChildReady={handleChildReady}
            indicatorOptions={indicatorOptions}
            groupedModules={groupedModulesDashboard}
            activeGeography={activeGeography}
            allData={allData}
            moduleIdIncrement={moduleIdIncrement}
        /> 
        : <Comparisons
            key={`Comparisons-${activeGeography}`}
            indicatorOptions={indicatorOptions}
            groupedModules={groupedModules}
            activeGeography={activeGeography}
            allData={allData}
        />} />
      </Routes>
    </>
  );
}

function App() {
  const [display, setDisplay] = useState<string>("menu"); // menu, search
  const [resultsToDisplay, setResultsToDisplay] = useState<boolean>(false); // Controls search results display
  const [displayContext, setDisplayContext] = useState<string>("closed"); // closed, menuOpen, searchOpen
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false); // Control fullscreen search popup
  const [indicatorOptions, setIndicatorOptions] = useState<any | null>(null);
  const [groupedModules, setGroupedModules] = useState<any | null>(null);
  const [groupedModulesDashboard, setGroupedModulesDashboard] = useState<any | null>(null);
  const [searchData, setSearchData] = useState<any>(null);
  const [allData, setAllData] = useState<any | null>(null);
  const [moduleIdIncrement, setModuleIdIncrement] = useState<number>(0);

  const activeGeography = useGlobalActiveGeography();
  const selectedId = useGlobalSelectedId();

  const toggleMenu = () => {
    // Using state variables in the return for this component will cause costly re-renders, 
    // lets do it the old-fashioned way here.
    const mainContent = document.querySelector('.main-content');
    if (mainContent) {
      setDisplay(displayContext === "closed" ? "menu" : display);
      setDisplayContext(displayContext === "closed" ? "menuOpen" : "closed");
      mainContent.classList.toggle('show-menu'); // Toggle the 'show-menu' class directly
    }
  };

  useLayoutEffect(() => {
    // This function runs after all child components have rendered
    console.log("100424 all components rendered selectedId", selectedId)
  }, []);

  useEffect(() => {
    console.log("100124 selectedId",selectedId);
  }, [selectedId]);

  const toggleSearchPopup = () => {
    console.log('Search button clicked');
    setIsSearchOpen(!isSearchOpen); // Toggle fullscreen popup
  };

  const closeSearchPopup = () => {
    setIsSearchOpen(false); // Close the popup
  };

  return (
    <DataProvider title="NYC Data2Go">
      <Router>
        {/* Ensure all components using Router hooks are within the Router context */}
        <div className="top-navbar">
          <div className="menu-toggle" onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <div>
            <img src={MoALogo} alt="Measure of America" className="moa-logo" />
          </div>
          <div className="navbar-title">NYC<b>DATA</b>2<b>GO</b></div>
          <div className="header-message-center">
            <div className="exporting-spinner off" id='exportingSpinner'>
              Exporting.
              <div className="export-spinner">
                <FontAwesomeIcon icon={faSpinner} spin />
              </div>`
            </div>
          </div>
          <div className="magnify-search-button"
            onClick={() => {
              console.log('Search button clicked'); // Logging button click for debug
              toggleSearchPopup();
            }}
          >
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="18.3337" cy="11.6667" r="9.91667" fill="none" stroke="black" strokeWidth="3.5" />
              <path d="M2 28.0001L11.3333 18.6667" stroke="black" strokeWidth="3.5" strokeLinecap="round" />
            </svg>
          </div>
        </div>
        {/* Fullscreen Search Popup */}
        <SearchPopup 
          isOpen={isSearchOpen} 
          onClose={toggleSearchPopup} 
          searchData={searchData}
          groupedModules={groupedModules}
          allData={allData}
          setModuleIdIncrement={setModuleIdIncrement}
          moduleIdIncrement={moduleIdIncrement}
        />
        <div className={`main-content`}>
          <Navigation     
            searchData={searchData}
            setSearchData={setSearchData}    
            indicatorOptions={indicatorOptions}
            groupedModules={groupedModules}
            groupedModulesDashboard={groupedModulesDashboard}
            allData={allData}
            setAllData={setAllData}
            setGroupedModules={setGroupedModules}
            setGroupedModulesDashboard={setGroupedModulesDashboard}
            setIndicatorOptions={setIndicatorOptions}
            setModuleIdIncrement={setModuleIdIncrement}
            moduleIdIncrement={moduleIdIncrement}
            toggleMenu={toggleMenu}

          /> {/* Navigation is within the Router */}
        </div>
      </Router>
    </DataProvider>
  );
}

export default App;
