import React, { useState, useEffect, useMemo, useRef } from 'react';
import * as d3 from "d3";
import { useGlobalSelectedId, onSetActiveIndicator } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';
import Colors from '../utilities/Colors';
import PieMeta from './PieMeta';

export interface PieChartProps {
  options: any;
  data: any;
  width: number;
  height: number;
  colorClass: string;
}

const MARGIN_X = 15;
const MARGIN_Y = 15;

const colors = Colors.wafflePieColors;

// Define an interface that extends SVGPathElement to include _current
interface PathElement extends SVGPathElement {
  _current?: d3.PieArcDatum<any>;
}

const Pie: React.FC<PieChartProps> = ({ options, data, width, height, colorClass }) => {
  const [preppedData, setPreppedData] = useState<any>([]);
  const [hoveredIndicator, setHoveredIndicator] = useState<any>(null);
  const selectedId = useGlobalSelectedId();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({ width, height });

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        if (!entries || entries.length === 0) {
          return;
        }
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (selectedId) {
      prepData();
    }
  }, [selectedId]);

  useEffect(() => {
    if (selectedId) {
      prepData();
    }
  }, []);

  const prepData = () => {
    if (selectedId) {
      const _preppedData = options.variables.map((v: any) => {
        return {
          name: v.label,
          value: data.dataJSON[selectedId][v.variable],
          variable: v.variable
        };
      });
      setPreppedData(_preppedData);
    }
  };

  const total = preppedData.reduce((acc: number, d: any) => acc + d.value, 0);
  const chartSize = Math.min(dimensions.width / 2, dimensions.height / 2);
  const radius = chartSize / 2;

  const pie = useMemo(() => {
    const pieGenerator = d3.pie<any, any>().value((d) => d.value);
    return pieGenerator(preppedData);
  }, [preppedData]);

  const arcGenerator = d3.arc<d3.PieArcDatum<any>>()
    .innerRadius(0)
    .outerRadius(radius + 5);

  useEffect(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);
    const g = svg.select('g.container');

    // Bind data
    const paths = g.selectAll<PathElement, d3.PieArcDatum<any>>('path').data(pie);

    // Enter new paths
    paths.enter()
      .append<PathElement>('path')
      .attr('fill', (d, i) => colors[i])
      .each(function (d) {
        const thisPath = this as PathElement;
        thisPath._current = d; // Store initial state for tweening
      })
      .attr('d', arcGenerator)
      .style('cursor', 'pointer')
      .on('click', (event, d) => onClicked(d.data.variable))
      .on('mouseenter', (event, d) => onHovered(d.data.variable))
      .on('mouseleave', (event, d) => onLeave());

    // Update existing paths
    paths.transition()
      .duration(750)
      .attrTween('d', function (d) {
        const thisPath = this as PathElement;
        const interpolate = d3.interpolate(thisPath._current, d);
        thisPath._current = interpolate(1);
        return (t) => arcGenerator(interpolate(t)) || '';
      });

    // Remove old paths
    paths.exit().remove();

  }, [pie, radius]);

  const onClicked = (variable: any) => {
    onSetActiveIndicator(variable);
  };
  const onHovered = (variable: any) => {
    options.variables.forEach((v: any) => {
      if (v.variable === variable) {
        setHoveredIndicator(v);
      }
    });
  };
  const onLeave = () => {
    // setHoveredIndicator(null); // uncomment to reset to first listed indicator on mouse leave
  };

  const fontSize = 12;
  const fontSize2 = 12;
  const valueWidth = 120;
  const labelWidth = dimensions.width - radius * 2 - valueWidth - 30;

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'flex-start', paddingRight: "16px" }}>
      <div
        style={{
          marginLeft: '0px',
          marginRight: '0px',
          fontSize: fontSize2,
          lineHeight: `${fontSize2 + 2}px`,
          width: '250px',
        }}>
        <PieMeta
          dataArray={data.dataArray[hoveredIndicator ? hoveredIndicator : options.variables[0]]}
          data={data}
          indicator={hoveredIndicator ? hoveredIndicator : options.variables[0]}
        />
      </div>
      <div
        style={{
          marginRight: '0px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <svg
          ref={svgRef}
          width={radius * 2 + 10}
          height={radius * 2 + 10}
          style={{
            display: "inline-block"
          }}>
          <g
            transform={`translate(${(radius + 5)}, ${(radius + 5)})`}
            className={"container"}
          >
            {/* Paths will be handled by D3 */}
          </g>
        </svg>
      </div>
      <div
        style={{
          marginLeft: '0px',
          fontSize,
          lineHeight: `${fontSize + 2}px`,
          display: 'flex',
          flexDirection: 'column',
          width: `calc(100% - ${((radius * 2) + 10)}px - 250px)`,
          paddingTop: '20px',
          //height: "145px",
          //justifyContent: "center",
        }}>
        {preppedData.map((d: any, i: number) => {
          const name = `${d.name}`;

          return (
            <div key={i} style={{ marginBottom: '5px', cursor: 'pointer', display: 'flex', paddingBottom: '12px' }} onClick={() => onClicked(d.variable)}>
              <div style={{ width: `${valueWidth}px`, fontWeight: 'bold', color: Colors.black, textAlign: 'left', paddingLeft: '5px' }}>
                {d2gRound(d.value, { DESCRIPTOR: data.meta["DESCRIPTOR"][d.variable] })}
              </div>
              <div style={{ width: `${labelWidth}px`, color: Colors.wafflePieColors[i], textAlign: 'left', whiteSpace: 'normal', overflowWrap: 'break-word', paddingLeft: "5px" }}>
                {name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Pie;
