import React from 'react';
import Colors from '../utilities/Colors';
// Import the functions directly from the store
import { HistogramStatic } from './HistogramStatic';
import { HistogramDynamic } from './HistogramDynamic';

interface DataItem {
    id: string;
    value: any; // Consider specifying a more detailed type here
}

export interface HistogramProps {
  dataArray: DataItem[];
  histMax: number;
  histNA: number;
  histLength: number; 
  chartId: string;
  height: string;
  width: string;
  paddingLeft: string;
  paddingRight: string;
  tooltips: boolean;
  colorClass: string;
  data: any;
  indicator: any;
}

export const Histogram: React.FC<HistogramProps> = ({ 
    dataArray, histMax, histNA, histLength, chartId, height, width, paddingLeft, paddingRight, tooltips, colorClass, indicator, data
}) => {
    //console.log("D100724 dataArray", dataArray);
    //console.log("K092924 histMax", histMax);
    //console.log("K092924 histNA", histNA);
    //console.log("K092924 histLength", histLength);
    //console.log("D100724 chartId", chartId);
    return (
        <>            
            <HistogramDynamic
                dataArray={dataArray}
                histMax={histMax}
                histNA={histNA} 
                histLength={histLength} 
                chartId={chartId}
                height={height}
                width={width}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                tooltips={tooltips}
                DESCRIPTOR={data.meta["DESCRIPTOR"][indicator.variable]}
            />
            <HistogramStatic 
                dataArray={dataArray}
                histMax={histMax}
                histNA={histNA} 
                histLength={histLength} 
                chartId={chartId}
                height={height}
                width={width}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                colorClass={colorClass}
                DESCRIPTOR={data.meta["DESCRIPTOR"][indicator.variable]}
            />
        </>
    );
};
