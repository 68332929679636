import React from 'react';
import Select from 'react-select';
import { useGlobalActiveIndicator, useGlobalBivariateIndicator, onSetBivariateIndicator, onSetActiveIndicator, useGlobalActiveGeography } from '../../data/StatusStore';

interface IndicatorDropdownProps {
  options: any;
}

const IndicatorDropdown: React.FC<IndicatorDropdownProps> = ({ options }) => {
  console.log("H100524 options: ", options);
  const activeIndicator = useGlobalActiveIndicator();
  const activeGeography = useGlobalActiveGeography();
  const bivariateIndicator = useGlobalBivariateIndicator();
  console.log("I100524 activeIndicator: ", activeIndicator);
  console.log("I100524 bivariateIndicator: ", bivariateIndicator);
  console.log("I100524 activeGeography: ", activeGeography);
  const formattedOptions:any[] = [];
  Object.keys(options.DISPLAY_NAME).forEach(key => {
    if (options[activeGeography][key] == "X"){
      formattedOptions.push(
        {
          value: key,
          label: options.DISPLAY_NAME[key]
        }
      );
    }
  });

  const handleActiveIndicatorChange = (selectedOption: any) => {
    onSetActiveIndicator(selectedOption.value);
  };

  const handleBivariateIndicatorChange = (selectedOption: any) => {
    onSetBivariateIndicator(selectedOption.value);
  };

  return (
    <div>
      <Select
        key={`${activeGeography}_select_1_${activeIndicator}`}
        options={formattedOptions}
        defaultValue={{ value: activeIndicator, label: options.DISPLAY_NAME[activeIndicator] }}
        onChange={handleActiveIndicatorChange}
        placeholder="Select Active Indicator"
      />
      <Select
        key={`${activeGeography}_select_2_${bivariateIndicator}`}
        options={formattedOptions}
        defaultValue={{ value: bivariateIndicator, label: options.DISPLAY_NAME[bivariateIndicator] }}
        onChange={handleBivariateIndicatorChange}
        placeholder="Select Bivariate Indicator"
      />
    </div>
  );
};

export default IndicatorDropdown;
