import React, { useEffect, useState } from 'react';
import { useMap, useMapEvent } from 'react-leaflet';
import L from 'leaflet';
import { onSetActiveGeography, useGlobalActiveGeography, onSetCachedActiveIndicators,  onSetCachedSelectedIds, useGlobalCachedActiveIndicators, useGlobalCachedSelectedIds, onSetActiveIndicator, onSetBivariateIndicator } from '../../data/StatusStore';

interface MapRadioControlProps {
  categoryOptions: { category:string; options:any }[];
  defaultValue: string;
  onChange: (value: string) => void;
}

const MapRadioControl: React.FC<MapRadioControlProps> = ({ categoryOptions, defaultValue, onChange }) => {
  const map = useMap();
  const activeGeography = useGlobalActiveGeography();
  //console.log("I092924 activeGeography", activeGeography);
  const cachedActiveIndicators = useGlobalCachedActiveIndicators();
  //const cachedSelectedIds = useGlobalCachedSelectedIds();
  const [selectedValue, setSelectedValue] = useState<string>(activeGeography);

  useEffect(() => {
    // Create a Leaflet Control to position the radio buttons on the map
    const controlContainer = L.DomUtil.create('div', 'leaflet-control leaflet-bar');
    const customControl = new L.Control({ position: 'topright' }); // You can adjust the position

    customControl.onAdd = function() {
      L.DomEvent.disableClickPropagation(controlContainer);
      return controlContainer;
    };

    customControl.addTo(map);

    return () => {
      customControl.remove();
    };
  }, [map]);

  useEffect(() => {
    setSelectedValue(activeGeography);
  }, [activeGeography]);

  useMapEvent('click', () => {
    // Optional: Respond to map clicks or other events
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, category:string) => {
    switch (category) {
        case "by Geography":
          //onSetBivariateIndicator(cachedActiveIndicators[e.target.value].bivariate); // This will prevent the cached bivariateIndicator from being set
          //onSetActiveIndicator(cachedActiveIndicators[e.target.value].active); // This will prevent the cached activeIndicator from being set
          onSetActiveGeography(e.target.value);
          break;
        default:
            break;
    }
    onChange(e.target.value);
  };

  return (
    <div className="leaflet-control-container">
    <div className="leaflet-top leaflet-left">
    <div className="leaflet-control leaflet-control-custom" id='mapRadios'>
        {categoryOptions.map((category:any) => {
            return (
              <form 
                key={`${category.category}_form`}
                className="map-radio-control">
                <label 
                key={`${category.category}_label`}
                className="map-radio-control-label">{category.category}</label>{
                category.options.map((option:any) => {
                return (
                    <label key={`${option.value}_${category.category}_label`}>
                        <input
                            key={`${option.value}_${category.category}_input`}
                            disabled={!option.enabled}
                            type="radio"
                            name="mapRadioControl"
                            value={option.value}
                            checked={selectedValue === option.value}
                            onChange={(e: any) => {
                                handleChange(e, category.category);
                            }}
                        />
                        <div
                          className="map-radio-control-text"
                        >{option.label}</div>
                    </label>
                )})}
            </form>
        )})}
    </div>
    </div>
    </div>
  );
};

export default MapRadioControl;
