import React, { useState, useEffect, useRef } from 'react';
import { useGlobalSelectedId, onSetActiveIndicator } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';
import Colors from '../utilities/Colors';
import BarMeta from './BarMeta';

export interface BarChartProps {
  options: any;
  data: any;
  width: number;
  height: number;
  colorClass: string;
}

const MARGIN_X = 0;
const MARGIN_Y = 15;

const Bar: React.FC<BarChartProps> = ({ options, data, width, height, colorClass }) => {
  const [preppedData, setPreppedData] = useState<any>([]);
  const [preppedLabelData, setPreppedLabelData] = useState<any>({});
  const [hoveredIndicator, setHoveredIndicator] = useState<any>(null);
  const [barHeight, setBarHeight] = useState<number>(0);
  const selectedId = useGlobalSelectedId();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({ width, height });

  // Calculate a consistent max value across all data points
  //console.log("E103124 data", data)
  //console.log("E103124 options.variables", options.variables)
  const globalMaxValue = Math.max(
    ...Object.values(data.dataJSON).flatMap((d: any) => {
      return options.variables.map((v: any) => {
        const value = d[v.variable];
        //console.log("E103124", v.variable, value);
        return isNaN(value) ? 0 : value; // Filter out non-numeric values
      });
    })
  );

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        if (!entries || entries.length === 0) return;
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (selectedId) {
      prepData();
    }
  }, [selectedId]);

  const prepData = () => {
    if (selectedId) {
      const _filteredData = options.variables.filter((v: any) => !v.sort.Tooltip_only);
      const _remainingData = options.variables.filter((v: any) => v.sort.Tooltip_only);
      const _preppedData = _filteredData.map((v: any) => (
      //console.log("E103124 v", v),  
      //console.log("E103124 data.dataJSON[selectedId]", data.dataJSON[selectedId]),  
      //console.log("E103124 data.dataJSON[selectedId][v.variable]", data.dataJSON[selectedId][v.variable]),  
      {
        name: v.label,
        value: data.dataJSON[selectedId][v.variable],
        variable: v.variable,
        avg: data.meta.avg[v.variable],
        index: data.keyIndexDataArray[v.variable] ? data.keyIndexDataArray[v.variable][selectedId].index : 0,
        na: data.meta.na_count[v.variable],
        len: data.dataArray[v.variable] ? data.dataArray[v.variable].length : 0,
      }));
      const _preppedLabelData: { [key: string]: any } = {};
      _remainingData.forEach((v: any) => {
        if (v.sort.Tooltip_only && v.sort.Tooltip_only !== "" ){
          _preppedLabelData[v.sort.Tooltip_only] = {
            name: v.sort.DISPLAY_NAME,
            value: data.dataJSON[selectedId][v.variable],
            variable: v.variable,
          }
        }
      });
      setPreppedData(_preppedData);
      setPreppedLabelData(_preppedLabelData);
    }
  };

  useEffect(() => {
    setBarHeight(27.5); // Set a fixed height for each bar
  }, [preppedData]);

  // Adjust the bar scale to use the global max value
  const barScale = (value: number) => {
    //console.log("E103124 value", value);
    //console.log("E103124 globalMaxValue", globalMaxValue);
    //console.log("E103124 dimensions.width", dimensions.width);
    //return (value / globalMaxValue) * (dimensions.width / 2)
    const maxWidth = dimensions.width - (2 * MARGIN_X);
    return ((value / globalMaxValue) * maxWidth) / 2.5;
  };

  const onClicked = (variable: string) => {
    onSetActiveIndicator(variable);
  };

  const onHovered = (variable: any) => {
    const hoveredVar = options.variables.find((v: any) => v.variable === variable);
    setHoveredIndicator(hoveredVar);
  };

  const onLeave = () => {
    // Uncomment to reset to first listed indicator on mouse leave
    // setHoveredIndicator(null);
  };

  const fontSize = 12;
  const fontSize2 = 12;
  const labelWidth = 250;
  const chartWidth = `calc(100% - ${labelWidth * 2}px)`;

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'flex-start', paddingRight: "16px" }}>
      <div style={{
          marginLeft: '0px', 
          marginRight: '0px', 
          fontSize: fontSize2, 
          lineHeight: `${fontSize2 + 2 }px`,
          width: "250px" 
        }}>
        <BarMeta
          dataArray={data.dataArray[hoveredIndicator ? hoveredIndicator : options.variables[0]]}
          data={data}
          indicator={hoveredIndicator ? hoveredIndicator : options.variables[0]}
        />
      </div>
      <div style={{
          marginLeft: '0px',
          marginRight: '0px',
          fontSize: fontSize2,
          lineHeight: `${fontSize2 + 2}px`,
          width: `${labelWidth}px`,
        }}
      >
        {preppedData.map((d: any, i: number) => (
          <div key={`info-${i}`}
            style={{
              fontSize: fontSize2,
              lineHeight: 0.95,
              display: "grid",
              justifyContent: "space-between",
              height: `${barHeight}px`,
              marginRight: "15px",
            }}
          >
            <div style={{ fontWeight: 'normal', width: "100%", alignContent: "center", paddingRight: "0px" }}>
              {d.name.split("(")[0].replace("Industry Category: ", "").replace("Occupational Category: ", "")}
            </div>
          </div>
        ))}
      </div>
      <div style={{
          fontSize,
          lineHeight: `${fontSize + 2}px`,
          display: 'flex',
          width: chartWidth,
          position: "relative"
        }}
      >
        <svg width="100%" height={barHeight * preppedData.length} style={{ display: "inline-block" }}>
          <g transform={`translate(${MARGIN_X}, 0)`}>
            {preppedData.map((d: any, i: number) => (
              //console.log("D103124 d", d),
              <g key={`bar-${i}`} transform={`translate(0, ${i * barHeight})`}>
                <rect
                  x={0}
                  y={0}
                  width={barScale(d.value)} // Scale bar width to global max value
                  height={barHeight - 2}
                  fill={Colors.getColorQuintile(d.index, d.na, d.len, `${colorClass}_variant`)}
                  onClick={() => onClicked(d.variable)}
                  onMouseEnter={() => onHovered(d.variable)}
                  onMouseLeave={onLeave}
                  style={{ cursor: 'pointer', transition: 'width 0.5s ease, fill 0.5s ease' }} // Animated transition for width and color
                />
                <line
                  x1={barScale(d.avg)}
                  y1={0}
                  x2={barScale(d.avg)}
                  y2={barHeight - 2}
                  stroke="#9E9C9C"
                  strokeWidth={1}
                  strokeDasharray="4"
                />
                <text
                  x={5}
                  y={barHeight / 2}
                  alignmentBaseline="middle"
                  fill={Colors.processBlack}
                  fontSize={fontSize}
                  pointerEvents="none"
                >
                  { `${d2gRound(d.value, { DESCRIPTOR: data.meta["DESCRIPTOR"][d.variable] })}`}
                  {preppedLabelData[d.variable] ? ` (${d2gRound(preppedLabelData[d.variable].value, { DESCRIPTOR: data.meta["DESCRIPTOR"][preppedLabelData[d.variable].variable] })})` : null }
                </text>
              </g>
            ))}
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Bar;
