import React from 'react';

// Define the SVGs
const svgMap = {
  calculator: (
    <svg viewBox="0 0 20 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_27_5368)">
        <path d="M2.91152 0.352539H17.0885C18.4984 0.352539 19.6475 1.50164 19.6475 2.91158V25.245C19.6475 26.655 18.4984 27.8041 17.0885 27.8041H2.91152C1.50158 27.8041 0.352478 26.655 0.352478 25.245V2.91158C0.352478 1.50164 1.50158 0.352539 2.91152 0.352539Z" stroke="#FDB913" strokeWidth="2"/>
        <path d="M2.91152 0.352539H17.0885C18.4984 0.352539 19.6475 1.50164 19.6475 2.91158V25.245C19.6475 26.655 18.4984 27.8041 17.0885 27.8041H2.91152C1.50158 27.8041 0.352478 26.655 0.352478 25.245V2.91158C0.352478 1.50164 1.50158 0.352539 2.91152 0.352539Z" stroke="#FDB913" strokeWidth="2"/>
        <path d="M4.15227 3.30664H15.8548C16.4822 3.30664 16.9968 3.82127 16.9968 4.44869V6.88789C16.9968 7.51531 16.4822 8.02994 15.8548 8.02994H4.15227C3.5178 8.02994 3.01022 7.51531 3.01022 6.88789V4.45574C3.01022 3.82832 3.52485 3.31369 4.15227 3.31369V3.30664Z" stroke="#FDB913" strokeWidth="2"/>
        <path d="M6.87345 12.5127C6.87345 13.5067 6.06979 14.3175 5.06873 14.3175C4.06767 14.3175 3.26401 13.5138 3.26401 12.5127C3.26401 11.5117 4.06767 10.708 5.06873 10.708C6.06979 10.708 6.87345 11.5117 6.87345 12.5127Z" stroke="#FDB913" strokeWidth="2"/>
        <path d="M12.0479 12.5127C12.0479 13.5067 11.2443 14.3175 10.2432 14.3175C9.24216 14.3175 8.44554 13.5138 8.44554 12.5127C8.44554 11.5117 9.24921 10.708 10.2432 10.708C11.2372 10.708 12.0479 11.5117 12.0479 12.5127Z" stroke="#FDB913" strokeWidth="2"/>
        <path d="M17.2154 12.5127C17.2154 13.5067 16.4117 14.3175 15.4107 14.3175C14.4096 14.3175 13.6059 13.5138 13.6059 12.5127C13.6059 11.5117 14.4166 10.708 15.4107 10.708C16.4047 10.708 17.2154 11.5117 17.2154 12.5127Z" stroke="#FDB913" strokeWidth="2"/>
        <path d="M6.87345 17.7725C6.87345 18.7665 6.06979 19.5772 5.06873 19.5772C4.06767 19.5772 3.26401 18.7665 3.26401 17.7725C3.26401 16.7785 4.06767 15.9678 5.06873 15.9678C6.06979 15.9678 6.87345 16.7714 6.87345 17.7725Z" stroke="#FDB913" strokeWidth="2"/>
        <path d="M12.0479 17.7725C12.0479 18.7665 11.2443 19.5772 10.2432 19.5772C9.24216 19.5772 8.44554 18.7665 8.44554 17.7725C8.44554 16.7785 9.24921 15.9678 10.2432 15.9678C11.2372 15.9678 12.0479 16.7714 12.0479 17.7725Z" stroke="#FDB913" strokeWidth="2"/>
        <path d="M17.2154 17.7725C17.2154 18.7665 16.4117 19.5772 15.4107 19.5772C14.4096 19.5772 13.6059 18.7665 13.6059 17.7725C13.6059 16.7785 14.4166 15.9678 15.4107 15.9678C16.4047 15.9678 17.2154 16.7714 17.2154 17.7725Z" stroke="#FDB913" strokeWidth="2"/>
        <path d="M6.87345 23.0244C6.87345 24.0255 6.06979 24.8292 5.06873 24.8292C4.06767 24.8292 3.26401 24.0255 3.26401 23.0244C3.26401 22.0234 4.06767 21.2197 5.06873 21.2197C6.06979 21.2197 6.87345 22.0234 6.87345 23.0244Z" stroke="#FDB913" strokeWidth="2"/>
        <path d="M12.0479 23.0244C12.0479 24.0255 11.2443 24.8292 10.2432 24.8292C9.24216 24.8292 8.44554 24.0255 8.44554 23.0244C8.44554 22.0234 9.24921 21.2197 10.2432 21.2197C11.2372 21.2197 12.0479 22.0234 12.0479 23.0244Z" stroke="#FDB913" strokeWidth="2"/>
        <path d="M17.2154 23.0244C17.2154 24.0255 16.4117 24.8292 15.4107 24.8292C14.4096 24.8292 13.6059 24.0255 13.6059 23.0244C13.6059 22.0234 14.4166 21.2197 15.4107 21.2197C16.4047 21.2197 17.2154 22.0234 17.2154 23.0244Z" stroke="#FDB913" strokeWidth="2"/>
      </g>
      <defs>
        <clipPath id="clip0_27_5368">
          <rect width="20" height="28.1495" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  ),
  briefcase: (
    <svg viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.8125 4.96379C10.8125 3.3337 12.2626 2 14.0462 2H15.6287C17.4069 2 18.8624 3.3337 18.8624 4.96379C18.8624 6.59387 17.4069 7.92228 15.6287 7.92228H14.0462C12.2679 7.92228 10.8125 6.58858 10.8125 4.96379Z" fill="white"/>
      <path d="M10.8125 4.96379C10.8125 3.3337 12.2626 2 14.0462 2H15.6287C17.4069 2 18.8624 3.3337 18.8624 4.96379C18.8624 6.59387 17.4069 7.92228 15.6287 7.92228H14.0462C12.2679 7.92228 10.8125 6.58858 10.8125 4.96379Z" fill="white"/>
      <path d="M10.8125 4.96379C10.8125 3.3337 12.2626 2 14.0462 2H15.6287C17.4069 2 18.8624 3.3337 18.8624 4.96379C18.8624 6.59387 17.4069 7.92228 15.6287 7.92228H14.0462C12.2679 7.92228 10.8125 6.58858 10.8125 4.96379Z" stroke="#B0BC22" strokeWidth="3.28" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M27.6632 5H2V20.5705H27.6632V5Z" fill="white"/>
      <path d="M27.6632 5H2V20.5705H27.6632V5Z" fill="white"/>
      <path d="M27.6632 5H2V20.5705H27.6632V5Z" stroke="#B0BC22" strokeWidth="2.19" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.5968 17.0984V18.4957H14.3371V17.1461C13.1463 17.109 12.2043 16.7968 11.3945 15.9764L12.5165 14.8544C13.0405 15.3784 13.7497 15.5795 14.5012 15.6007V13.3461L14.099 13.2931C13.3528 13.1979 12.7547 12.9227 12.3789 12.5469C11.9555 12.1235 11.7438 11.5625 11.7438 10.8163C11.7438 9.38201 12.7124 8.32351 14.3371 8.14886V7.02686H15.5968V8.13298C16.6076 8.20179 17.3803 8.50875 18.0525 9.15972L16.9516 10.2447C16.5441 9.84775 15.9302 9.68368 15.4221 9.64663V11.8165L15.8931 11.8906C16.7029 12.0177 17.2163 12.2399 17.5867 12.5892C18.0578 13.0338 18.2695 13.6742 18.2695 14.4575C18.2695 15.9817 17.1474 16.9185 15.5915 17.1037L15.5968 17.0984ZM14.5012 9.65722C13.7761 9.75248 13.4163 10.2023 13.4163 10.7581C13.4163 10.975 13.4904 11.2185 13.6756 11.3879C13.8767 11.5731 14.1466 11.6895 14.4959 11.7372V9.65192L14.5012 9.65722ZM16.3059 13.7536C16.0572 13.5207 15.745 13.4784 15.4221 13.4308V15.5742C16.1684 15.4842 16.5812 15.1138 16.5812 14.5051C16.5812 14.1929 16.4965 13.9282 16.3059 13.7536Z" fill="white"/>
      <path d="M15.5968 17.0984V18.4957H14.3371V17.1461C13.1463 17.109 12.2043 16.7968 11.3945 15.9764L12.5165 14.8544C13.0405 15.3784 13.7497 15.5795 14.5012 15.6007V13.3461L14.099 13.2931C13.3528 13.1979 12.7547 12.9227 12.3789 12.5469C11.9555 12.1235 11.7438 11.5625 11.7438 10.8163C11.7438 9.38201 12.7124 8.32351 14.3371 8.14886V7.02686H15.5968V8.13298C16.6076 8.20179 17.3803 8.50875 18.0525 9.15972L16.9516 10.2447C16.5441 9.84775 15.9302 9.68368 15.4221 9.64663V11.8165L15.8931 11.8906C16.7029 12.0177 17.2163 12.2399 17.5867 12.5892C18.0578 13.0338 18.2695 13.6742 18.2695 14.4575C18.2695 15.9817 17.1474 16.9185 15.5915 17.1037L15.5968 17.0984ZM14.5012 9.65722C13.7761 9.75248 13.4163 10.2023 13.4163 10.7581C13.4163 10.975 13.4904 11.2185 13.6756 11.3879C13.8767 11.5731 14.1466 11.6895 14.4959 11.7372V9.65192L14.5012 9.65722ZM16.3059 13.7536C16.0572 13.5207 15.745 13.4784 15.4221 13.4308V15.5742C16.1684 15.4842 16.5812 15.1138 16.5812 14.5051C16.5812 14.1929 16.4965 13.9282 16.3059 13.7536Z" fill="#B0BC22"/>
    </svg>
  ),
  person: (
    <svg viewBox="0 0 10 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.03691 17.5036L2.99319 16.5493H2.03795H1.62178C1.27319 16.5493 1 16.2671 1 15.9401V7.92453C1 7.60609 1.27073 7.3214 1.62178 7.3214H8.38434C8.72928 7.3214 9 7.60002 9 7.92453V15.9401C9 16.272 8.72799 16.5493 8.38434 16.5493H7.96819H7.05562L6.97236 17.4581C6.96523 17.5359 6.96109 17.5907 6.95772 17.6354C6.95278 17.7008 6.94946 17.7447 6.94078 17.8079L6.94072 17.8079L6.9393 17.8191L5.89288 26.1315C5.89287 26.1316 5.89286 26.1316 5.89285 26.1317C5.82326 26.68 5.38686 27 5.01837 27H4.98165C4.6212 27 4.18306 26.682 4.11326 26.1316C4.11325 26.1316 4.11325 26.1315 4.11324 26.1315L3.06279 17.8355L3.0621 17.828C3.0503 17.6994 3.04147 17.6032 3.03691 17.5036ZM6.9437 2.91477C6.9437 3.96907 6.07823 4.83561 5.00001 4.83561C3.9245 4.83561 3.06242 3.97176 3.06242 2.91477C3.06242 1.86048 3.92178 1 5.00001 1C6.0796 1 6.9437 1.86789 6.9437 2.91477Z" fill="white" stroke="#F26531" strokeWidth="2"/>
    </svg>    
  ),
  cap: (
    <svg viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.4537 6.00931L14.0213 1L1.58885 6.00931C0.803715 6.32726 0.803715 7.43684 1.58885 7.75479L14.0213 12.7641L26.4537 7.75479C27.2389 7.43684 27.2389 6.32726 26.4537 6.00931Z" fill="white" stroke="#00B5CC" strokeWidth="1.66" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.28764 9.31854V15.3012C5.42317 16.8585 7.61637 17.8512 9.63437 18.4547C12.4959 19.3177 15.5456 19.3177 18.4072 18.4547C20.4252 17.8448 22.6184 16.8585 23.7539 15.3012V9.31854" stroke="#00B5CC" strokeWidth="1.66" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.0215 6.88519H27.0444V13.2701" stroke="#00B5CC" strokeWidth="1.66" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M27.1284 13.2701H26.9532L25.8761 16.8195H28.2055L27.1284 13.2701Z" fill="white" stroke="#00B5CC" strokeWidth="1.66" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  ),
  scale: (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 25 28" fill="none">
      <g clip-path="url(#clip0_412_3255)">
        <path d="M5.08997 26.8799H19.39" stroke="#D53D8B" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.22998 3.45996H22.24" stroke="#D53D8B" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.24 26.8801V0.850098" stroke="#D53D8B" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M23.62 11.7998C23.62 13.8798 21.94 15.5598 19.86 15.5598C17.78 15.5598 16.1 13.8798 16.1 11.7998H23.62Z" stroke="#D53D8B" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M23.62 11.8L19.86 3.45996L16.1 11.8" stroke="#D53D8B" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.36998 11.7998C8.36998 13.8798 6.68998 15.5598 4.60998 15.5598C2.52998 15.5598 0.849976 13.8798 0.849976 11.7998H8.36998Z" stroke="#D53D8B" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.36998 11.8L4.60998 3.45996L0.849976 11.8" stroke="#D53D8B" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_412_3255">
          <rect width="24.47" height="27.73" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  ),
  heart: (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
      <g clip-path="url(#clip0_412_286)">
        <path d="M18.03 9.25C18.55 8.47 19.22 7.2 19.22 5.94C19.22 3.08 16.9 0.75 14.03 0.75C12.39 0.75 10.93 1.95 9.98 3.14C9.03 1.95 7.57 0.75 5.93 0.75C3.07 0.75 0.75 3.08 0.75 5.94C0.75 7.2 1.41 8.49 1.94 9.25C3.22 11.08 9.98 16.99 9.98 16.99C9.98 16.99 17.2 10.48 18.03 9.25Z" stroke="#EF6036" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_412_286">
          <rect width="19.98" height="17.75" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  ),
  circleGrid: (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path d="M6.22617 3.61308C6.22617 5.05625 5.05625 6.22617 3.61308 6.22617C2.16992 6.22617 1 5.05625 1 3.61308C1 2.16992 2.16992 1 3.61308 1C5.05625 1 6.22617 2.16992 6.22617 3.61308Z" fill="white" stroke="#FFC425" stroke-width="2"/>
      <path d="M13.4527 3.61308C13.4527 5.05625 12.2828 6.22617 10.8396 6.22617C9.39648 6.22617 8.22656 5.05625 8.22656 3.61308C8.22656 2.16992 9.39648 1 10.8396 1C12.2828 1 13.4527 2.16992 13.4527 3.61308Z" fill="white" stroke="#FFC425" stroke-width="2"/>
      <path d="M20.6716 3.61308C20.6716 5.05625 19.5017 6.22617 18.0585 6.22617C16.6154 6.22617 15.4454 5.05625 15.4454 3.61308C15.4454 2.16992 16.6154 1 18.0585 1C19.5017 1 20.6716 2.16992 20.6716 3.61308Z" fill="white" stroke="#FFC425" stroke-width="2"/>
      <path d="M6.22617 10.8328C6.22617 12.276 5.05625 13.4459 3.61308 13.4459C2.16992 13.4459 1 12.276 1 10.8328C1 9.38964 2.16992 8.21973 3.61308 8.21973C5.05625 8.21973 6.22617 9.38965 6.22617 10.8328Z" fill="white" stroke="#FFC425" stroke-width="2"/>
      <path d="M13.4527 10.8328C13.4527 12.276 12.2828 13.4459 10.8396 13.4459C9.39648 13.4459 8.22656 12.276 8.22656 10.8328C8.22656 9.38964 9.39648 8.21973 10.8396 8.21973C12.2828 8.21973 13.4527 9.38964 13.4527 10.8328Z" fill="white" stroke="#FFC425" stroke-width="2"/>
      <path d="M20.6716 10.8328C20.6716 12.276 19.5017 13.4459 18.0585 13.4459C16.6154 13.4459 15.4454 12.276 15.4454 10.8328C15.4454 9.38964 16.6154 8.21973 18.0585 8.21973C19.5017 8.21973 20.6716 9.38964 20.6716 10.8328Z" fill="white" stroke="#FFC425" stroke-width="2"/>
      <path d="M6.22617 18.0525C6.22617 19.4957 5.05625 20.6656 3.61308 20.6656C2.16992 20.6656 1 19.4957 1 18.0525C1 16.6094 2.16992 15.4395 3.61308 15.4395C5.05625 15.4395 6.22617 16.6094 6.22617 18.0525Z" fill="white" stroke="#FFC425" stroke-width="2"/>
      <path d="M13.4527 18.0525C13.4527 19.4957 12.2828 20.6656 10.8396 20.6656C9.39648 20.6656 8.22656 19.4957 8.22656 18.0525C8.22656 16.6094 9.39648 15.4395 10.8396 15.4395C12.2828 15.4395 13.4527 16.6094 13.4527 18.0525Z" fill="white" stroke="#FFC425" stroke-width="2"/>
      <path d="M20.6716 18.0525C20.6716 19.4957 19.5017 20.6656 18.0585 20.6656C16.6154 20.6656 15.4454 19.4957 15.4454 18.0525C15.4454 16.6094 16.6154 15.4395 18.0585 15.4395C19.5017 15.4395 20.6716 16.6094 20.6716 18.0525Z" fill="white" stroke="#FFC425" stroke-width="2"/>
    </svg>
  ),
  clock: (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path d="M21.0613 11.0306C21.0613 16.5704 16.5704 21.0613 11.0306 21.0613C5.49087 21.0613 1 16.5704 1 11.0306C1 5.49087 5.49087 1 11.0306 1C16.5704 1 21.0613 5.49088 21.0613 11.0306Z" fill="white" stroke="#FFC425" stroke-width="2"/>
      <path d="M11.0312 4.20459V11.0307L15.5904 9.08036" fill="white"/>
      <path d="M11.0312 4.20459V11.0307L15.5904 9.08036" stroke="#FFC425" stroke-width="1.66"/>
    </svg>
  ),
  bubble: (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path d="M6.22617 3.61308C6.22617 5.05625 5.05625 6.22617 3.61308 6.22617C2.16992 6.22617 1 5.05625 1 3.61308C1 2.16992 2.16992 1 3.61308 1C5.05625 1 6.22617 2.16992 6.22617 3.61308Z" fill="white" stroke="#FFC425" stroke-width="2"/>
      <path d="M13.4527 3.61308C13.4527 5.05625 12.2828 6.22617 10.8396 6.22617C9.39648 6.22617 8.22656 5.05625 8.22656 3.61308C8.22656 2.16992 9.39648 1 10.8396 1C12.2828 1 13.4527 2.16992 13.4527 3.61308Z" fill="white" stroke="#FFC425" stroke-width="2"/>
      <path d="M20.6716 3.61308C20.6716 5.05625 19.5017 6.22617 18.0585 6.22617C16.6154 6.22617 15.4454 5.05625 15.4454 3.61308C15.4454 2.16992 16.6154 1 18.0585 1C19.5017 1 20.6716 2.16992 20.6716 3.61308Z" fill="white" stroke="#FFC425" stroke-width="2"/>
      <path d="M6.22617 10.8328C6.22617 12.276 5.05625 13.4459 3.61308 13.4459C2.16992 13.4459 1 12.276 1 10.8328C1 9.38964 2.16992 8.21973 3.61308 8.21973C5.05625 8.21973 6.22617 9.38965 6.22617 10.8328Z" fill="white" stroke="#FFC425" stroke-width="2"/>
      <path d="M13.4527 10.8328C13.4527 12.276 12.2828 13.4459 10.8396 13.4459C9.39648 13.4459 8.22656 12.276 8.22656 10.8328C8.22656 9.38964 9.39648 8.21973 10.8396 8.21973C12.2828 8.21973 13.4527 9.38964 13.4527 10.8328Z" fill="white" stroke="#FFC425" stroke-width="2"/>
      <path d="M20.6716 10.8328C20.6716 12.276 19.5017 13.4459 18.0585 13.4459C16.6154 13.4459 15.4454 12.276 15.4454 10.8328C15.4454 9.38964 16.6154 8.21973 18.0585 8.21973C19.5017 8.21973 20.6716 9.38964 20.6716 10.8328Z" fill="white" stroke="#FFC425" stroke-width="2"/>
      <path d="M6.22617 18.0525C6.22617 19.4957 5.05625 20.6656 3.61308 20.6656C2.16992 20.6656 1 19.4957 1 18.0525C1 16.6094 2.16992 15.4395 3.61308 15.4395C5.05625 15.4395 6.22617 16.6094 6.22617 18.0525Z" fill="white" stroke="#FFC425" stroke-width="2"/>
      <path d="M13.4527 18.0525C13.4527 19.4957 12.2828 20.6656 10.8396 20.6656C9.39648 20.6656 8.22656 19.4957 8.22656 18.0525C8.22656 16.6094 9.39648 15.4395 10.8396 15.4395C12.2828 15.4395 13.4527 16.6094 13.4527 18.0525Z" fill="white" stroke="#FFC425" stroke-width="2"/>
      <path d="M20.6716 18.0525C20.6716 19.4957 19.5017 20.6656 18.0585 20.6656C16.6154 20.6656 15.4454 19.4957 15.4454 18.0525C15.4454 16.6094 16.6154 15.4395 18.0585 15.4395C19.5017 15.4395 20.6716 16.6094 20.6716 18.0525Z" fill="white" stroke="#FFC425" stroke-width="2"/>
    </svg>
  ),
  mapTic: (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="27" viewBox="0 0 18 27" fill="none">
      <g clip-path="url(#clip0_412_7614)">
        <path d="M16.33 8.5901C16.33 4.3101 12.86 0.850098 8.58998 0.850098C4.31998 0.850098 0.849976 4.3201 0.849976 8.5901C0.849976 12.8601 8.58998 25.9501 8.58998 25.9501C8.58998 25.9501 16.33 12.8701 16.33 8.5901Z" stroke="#8C71C7" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.58997 12.3298C10.7273 12.3298 12.46 10.5972 12.46 8.45984C12.46 6.3225 10.7273 4.58984 8.58997 4.58984C6.45263 4.58984 4.71997 6.3225 4.71997 8.45984C4.71997 10.5972 6.45263 12.3298 8.58997 12.3298Z" stroke="#8C71C7" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_412_7614">
          <rect width="17.18" height="26.8" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  ),
  sheld: (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" viewBox="0 0 20 25" fill="none">
      <g clip-path="url(#clip0_412_1839)">
        <path d="M18.94 3.3C13.82 5.69 9.92999 0.75 9.92999 0.75C9.92999 0.75 6.03999 5.69 0.919987 3.3C0.919987 3.3 -1.24001 17.11 9.92999 23.96C21.11 17.11 18.94 3.3 18.94 3.3Z" stroke="#1F61A9" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.54 6.71012C12.35 8.20012 9.93 5.12012 9.93 5.12012C9.93 5.12012 7.51 8.20012 4.32 6.71012C4.32 6.71012 2.97 15.3101 9.93 19.5801C16.89 15.3101 15.54 6.71012 15.54 6.71012Z" stroke="#1F61A9" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_412_1839">
          <rect width="19.86" height="24.71" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
};

interface SvgIconProps {
  svgName: 'calculator' | 'person' | 'briefcase' | 'cap' | 'scale' | 'heart' | 'circleGrid' | 'clock' | 'bubble' | 'mapTic';
}

const SvgIcon: React.FC<SvgIconProps> = ({ svgName }) => {
  return (
    <div style={{ 
      width: "24px", 
      height: "24px", 
      minWidth: "24px",
      display: "flex", 
      justifyContent: "center", 
      alignItems: "center", 
      backgroundColor: "transparent" 
    }}>
      <div style={{ width: "100%", height: "100%" }}>
        <svg 
          viewBox={svgMap[svgName].props.viewBox} 
          width="100%" 
          height="100%" 
          fill="none"
          preserveAspectRatio="xMidYMid meet"
        >
          {svgMap[svgName].props.children}
        </svg>
      </div>
    </div>
  );
};

export default SvgIcon;
