import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// Define props or configuration types if needed
interface ShortenerConfig {
  longUrl: string;
  apiKey: string;
  login: string;
}
const ActionButton = styled.div`
  padding: 10px 15px;
  margin-left:10px;
  border: none;
  border-radius: 0px;
  background-color: none;
  border: 1px solid #231f20;
  color: #231f20;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  width: 120px;
  min-height: 34px;
  &:hover {
    color: #a29f9f;
    border: 1px solid #a29f9f;
  }
`;
const TwitterShareButton: React.FC<ShortenerConfig> = ({ longUrl, apiKey, login }) => {
  // State to hold the shortened URL (or original if no shortening)
  const [shortUrl, setShortUrl] = useState<string>(longUrl);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Function to shorten URL using Bitly API
  const getShortUrl = async (longUrl: string) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api-ssl.bitly.com/v3/shorten?format=json&access_token=${apiKey}&longUrl=${encodeURIComponent(longUrl)}`
      );
      const data = await response.json();
      if (data.status_code === 200) {
        setShortUrl(data.data.url); // Set shortened URL
      } else {
        setError('Error shortening URL');
        setShortUrl(longUrl); // Fallback to original URL
      }
    } catch (err) {
      setError('Error fetching short URL');
      setShortUrl(longUrl); // Fallback to original URL
    } finally {
      setLoading(false);
    }
  };

  // Call the getShortUrl function if there's an API key and login
  useEffect(() => {
    if (apiKey && login) {
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          getShortUrl(longUrl);
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);

      // Cleanup the event listener on component unmount
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }
  }, [longUrl, apiKey, login]);

  // Function to handle Twitter sharing
  const handleShare = () => {
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      'Check out DATA2GO.NYC!'
    )}&url=${encodeURIComponent(shortUrl)}&hashtags=${encodeURIComponent('data2go')}`;
    window.open(tweetUrl, '_blank');
  };

  return (
   /* <div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {loading ? (
        <p>Loading shortened URL...</p>
      ) : (
        <button onClick={handleShare}>
          Share on Twitter
        </button>
      )}
    </div>*/
    <ActionButton onClick={handleShare}>Post to X</ActionButton>
  );
};

export default TwitterShareButton;
