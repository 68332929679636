// GlobalStore.tsx
import { useState, useEffect } from 'react';
import { debounce } from 'lodash';

type Subscriber<T> = (value: T) => void;

class GlobalStore {
  // INSTANTIATE VARIABLES
  private swarmCalibration:any = {};
  private swarmCalibrationSubscribers: Subscriber<any>[] = [];

  // DEFINE METHODS
  setSwarmCalibration = (config: any) => {
    this.swarmCalibration = config;
    this.swarmCalibrationSubscribers.forEach(sub => sub(this.swarmCalibration));
  };

  // SUBSCRIBE TO CHANGES
  subscribeToSwarmCalibration = (subscriber: Subscriber<any>) => {
    this.swarmCalibrationSubscribers.push(subscriber);
    // Return a function for unsubscribing
    return () => {
      this.swarmCalibrationSubscribers = this.swarmCalibrationSubscribers.filter(sub => sub !== subscriber);
    };
  };

  // GETTERS
  getSetSwarmCalibration = () => (config: any) => {
    this.setSwarmCalibration(config);
  };
}

// INSTANTIATE THE STORE
const globalStore = new GlobalStore();

// EXPORT THE STORE
export const useGlobalSwarmCalibration = () => {
  // Boro: 3, 12, 3, FALSE
  // CD: 1.3, 6, 45, FALSE
  // NTA: .5, 4, 25, FALSE
  // Tract: 0.17,1,35, FALSE
  const [swarmCalibration, setSwarmCalibration] = useState<any>({
    Tract:{
      radius: 1, // 2 "Radius"
      padding: 0.25, 
      pointDiameter: 0.75,
      collisionDiameter: 0.17, // 1 "Collision Threshold"
      stackCount:35, // 3 "Stack Count"
      testEachPoint: "FALSE", // 4 "Test Each Point"
    },
    CD:{
      radius: 6,
      padding: 0.5,
      pointDiameter: 2,
      collisionDiameter: 1.3,
      stackCount: 45,
      testEachPoint: "FALSE",
    },
    Boro:{
      radius: 12,
      padding: 0.5,
      pointDiameter: 2,
      collisionDiameter: 3,
      stackCount: 3,
      testEachPoint: "FALSE",
    },
    NTA:{
      radius: 4,
      padding: 0.5,
      pointDiameter: 2,
      collisionDiameter: 0.5,
      stackCount:25,
      testEachPoint: "FALSE",
    },
    ScatterPlot:{
      radius: 3,
    }
  });
  useEffect(() => {
    const unsubscribe = globalStore.subscribeToSwarmCalibration(setSwarmCalibration);
    return unsubscribe; // Correctly returning the unsubscribe function
  }, []);

  return swarmCalibration;
};

// EXPORT THE STORE METHODS
export const onSetSwarmCalibration = globalStore.getSetSwarmCalibration();
