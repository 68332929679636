import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useGlobalSelectedId, onSetActiveIndicator } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';
import Colors from '../utilities/Colors';

export interface PyramidChartProps {
  options: any;
  data: any;
  width: number; // This parameter will be ignored
  height: number;
  colorClass: string;
}

const Pyramid: React.FC<PyramidChartProps> = ({ options, data, height, colorClass }) => {
  const [preppedData, setPreppedData] = useState<any>([]);
  const [ageGroups, setAgeGroups] = useState<string[]>([]);
  const [barHeight, setBarHeight] = useState<number>(20);
  const [maxFemaleValue, setMaxFemaleValue] = useState<number>(0);
  const [maxMaleValue, setMaxMaleValue] = useState<number>(0);
  const selectedId = useGlobalSelectedId();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (selectedId) {
      prepData();
    }
  }, [selectedId]);

  const prepData = () => {
    if (selectedId) {
      const combinedData: any[] = [];
      const _filteredData = options.variables.filter((v: any) => v.sort.Tooltip_only === "");

      const ageGroupsArray = Array.from(new Set<string>(
        _filteredData.map((v: any) => v.label.replace(/(Females|Males|\(%\))/g, '').trim())
      )) as string[];

      const under5Index = ageGroupsArray.indexOf("Under 5");
      const fiveTo14Index = ageGroupsArray.indexOf("5-14");

      if (under5Index !== -1) {
        ageGroupsArray.splice(under5Index, 1);
      }
      if (fiveTo14Index !== -1) {
        ageGroupsArray.splice(fiveTo14Index > under5Index ? fiveTo14Index - 1 : fiveTo14Index, 1);
      }

      ageGroupsArray.unshift("Under 5", "5-14");
      setAgeGroups(ageGroupsArray);

      let globalMaxFemale = 0;
      let globalMaxMale = 0;

      ageGroupsArray.forEach((ageGroup) => {
        const femaleData = _filteredData.find((v: any) => v.label.includes('Females') && v.label.includes(ageGroup));
        const maleData = _filteredData.find((v: any) => v.label.includes('Males') && v.label.includes(ageGroup));

        if (femaleData) {
          const femaleValue = data.dataJSON?.[selectedId]?.[femaleData.variable] ?? 0;
          const maxFemaleValue = data.meta?.max_value?.[femaleData.variable] ?? 0;
          globalMaxFemale = Math.max(globalMaxFemale, maxFemaleValue);

          combinedData.push({
            name: ageGroup,
            value: femaleValue,
            avg: data.meta?.avg?.[femaleData.variable] ?? 0,
            category: 'Female',
            variable: femaleData.variable,
            index: data.keyIndexDataArray[femaleData.variable]?.[selectedId]?.index ?? 0,
            na: data.meta.na_count[femaleData.variable],
            len: data.dataArray[femaleData.variable]?.length ?? 0,
          });
        }

        if (maleData) {
          const maleValue = data.dataJSON?.[selectedId]?.[maleData.variable] ?? 0;
          const maxMaleValue = data.meta?.max_value?.[maleData.variable] ?? 0;
          globalMaxMale = Math.max(globalMaxMale, maxMaleValue);

          combinedData.push({
            name: ageGroup,
            value: maleValue,
            avg: data.meta?.avg?.[maleData.variable] ?? 0,
            category: 'Male',
            variable: maleData.variable,
            index: data.keyIndexDataArray[maleData.variable]?.[selectedId]?.index ?? 0,
            na: data.meta.na_count[maleData.variable],
            len: data.dataArray[maleData.variable]?.length ?? 0,
          });
        }
      });

      setPreppedData(combinedData);
      setMaxFemaleValue(globalMaxFemale);
      setMaxMaleValue(globalMaxMale);
    }
  };

  const barScaleFemale = (value: number) => (value / maxFemaleValue) * 50;
  const barScaleMale = (value: number) => (value / maxMaleValue) * 50;

  const onClicked = (variable: string) => {
    onSetActiveIndicator(variable);
  };

  const fontSize2 = 12;

  useEffect(() => {
    if (svgRef.current && preppedData.length > 0) {
      const svg = d3.select<SVGSVGElement, unknown>(svgRef.current);

      const groups = svg.selectAll<SVGGElement, string>('.age-group')
        .data(ageGroups, (d: string) => d)
        .join(
          (enter) => enter
            .append('g')
            .attr('class', 'age-group')
            .attr('transform', (d, i) => `translate(0, ${i * (barHeight + 10)})`),
          (update) => update.attr('transform', (d, i) => `translate(0, ${i * (barHeight + 10)})`),
          (exit) => exit.remove()
        );

      groups.each(function (ageGroup) {
        const group = d3.select<SVGGElement, string>(this);
        const femaleData = preppedData.find((d: any) => d.category === 'Female' && d.name === ageGroup);
        const maleData = preppedData.find((d: any) => d.category === 'Male' && d.name === ageGroup);

        
        // Left Bar (Female)
        group.selectAll('.bar-female')
          .data([femaleData])
          .join(
            (enter) => enter
              .append('rect')
              .attr('class', 'bar-female')
              .attr('x', '50%')
              .attr('y', 0)
              .attr('height', barHeight)
              .attr('width', 0)
              .attr('fill', (d) => Colors.getColorQuintile(d?.index, d.na, d.len, colorClass))
              .style('cursor', 'pointer')
              .on('click', (event, d) => onClicked(d?.variable))
              .call((enter) => enter.transition().duration(750)
                .attr('x', (d) => `${50 - barScaleFemale(d?.value || 0)}%`)
                .attr('width', (d) => `${barScaleFemale(d?.value || 0)}%`)
              ),

            (update) => update
              .call((update) => update.transition().duration(750)
                .attr('x', (d) => `${50 - barScaleFemale(d?.value || 0)}%`)
                .attr('width', (d) => `${barScaleFemale(d?.value || 0)}%`)
                .attr('fill', (d) => Colors.getColorQuintile(d?.index, d.na, d.len, colorClass))
              ),

            (exit) => exit.remove()
          );

        // Right Bar (Male)
        group.selectAll('.bar-male')
          .data([maleData])
          .join(
            (enter) => enter
              .append('rect')
              .attr('class', 'bar-male')
              .attr('x', '50%')
              .attr('y', 0)
              .attr('height', barHeight)
              .attr('width', 0)
              .attr('fill', (d) => Colors.getColorQuintile(d?.index, d.na, d.len, colorClass))
              .style('cursor', 'pointer')
              .on('click', (event, d) => onClicked(d?.variable))
              .call((enter) => enter.transition().duration(750)
                .attr('width', (d) => `${barScaleMale(d?.value || 0)}%`)
              ),

            (update) => update
              .call((update) => update.transition().duration(750)
                .attr('width', (d) => `${barScaleMale(d?.value || 0)}%`)
                .attr('fill', (d) => Colors.getColorQuintile(d?.index, d.na, d.len, colorClass))
              ),

            (exit) => exit.remove()
          );


          // Add Average Lines
        group.selectAll('.avg-line-female')
        .data([femaleData])
        .join(
          (enter) => enter
            .append('line')
            .attr('class', 'avg-line-female')
            .attr('x1', (d) => `${50 - barScaleFemale(d?.avg || 0)}%`)
            .attr('x2', (d) => `${50 - barScaleFemale(d?.avg || 0)}%`)
            .attr('y1', 0)
            .attr('y2', barHeight)
            .attr('stroke', '#9E9C9C')
            .attr('stroke-dasharray', '4 2')
            .raise(),

          (update) => update
            .call((update) => update.transition().duration(750)
              .attr('x1', (d) => `${50 - barScaleFemale(d?.avg || 0)}%`)
              .attr('x2', (d) => `${50 - barScaleFemale(d?.avg || 0)}%`)),

          (exit) => exit.remove()
        );

      group.selectAll('.avg-line-male')
        .data([maleData])
        .join(
          (enter) => enter
            .append('line')
            .attr('class', 'avg-line-male')
            .attr('x1', (d) => `${50 + barScaleMale(d?.avg || 0)}%`)
            .attr('x2', (d) => `${50 + barScaleMale(d?.avg || 0)}%`)
            .attr('y1', 0)
            .attr('y2', barHeight)
            .attr('stroke', '#9E9C9C')
            .attr('stroke-dasharray', '4 2')
            .raise(),

          (update) => update
            .call((update) => update.transition().duration(750)
              .attr('x1', (d) => `${50 + barScaleMale(d?.avg || 0)}%`)
              .attr('x2', (d) => `${50 + barScaleMale(d?.avg || 0)}%`)),

          (exit) => exit.remove()
        );

      });
    }
  }, [preppedData, maxFemaleValue, maxMaleValue, barHeight, colorClass, ageGroups]);

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'flex-start', paddingRight: "16px", position: 'relative' }}>
      <div style={{ marginLeft: '0px', marginRight: '0px', fontSize: fontSize2, lineHeight: `${fontSize2 + 2}px`, width: '250px' }}>
        <p style={{ marginRight: "15px" }}>
          Other information about this stat including the year of origin and relevant notes and maybe an assessment of how typical this percentage is.
          <br /><span style={{ color: '#ccc' }}>Source: Stats United</span>
        </p>
      </div>

      <div style={{ marginRight: '0px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'calc(100% - 400px)', position: 'relative' }}>
        <div style={{ width: '2px', height: `${(ageGroups.length * (barHeight + 10) - 10)}px`, backgroundColor: 'black', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}></div>
        <svg ref={svgRef} width="100%" height={(ageGroups.length * (barHeight + 10) - 10)}></svg>
      </div>

      <div style={{ width: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: `${fontSize2}px`, paddingLeft: '5px' }}>
        {ageGroups.map((ageGroup: string, i: number) => {
          const femaleData = preppedData.find((d: any) => d.category === 'Female' && d.name === ageGroup);
          const maleData = preppedData.find((d: any) => d.category === 'Male' && d.name === ageGroup);
          return (
            <div key={i} style={{ width: '100%', display: 'flex', alignItems: 'center', height: `${barHeight + 10}px` }}>
              <div style={{ width: '50%', textAlign: 'left' }}>
                <strong>{d2gRound(femaleData?.value + maleData?.value, { DESCRIPTOR: data.meta["DESCRIPTOR"][maleData?.variable] })}</strong>
              </div>
              <div style={{ width: '50%', textAlign: 'left', paddingLeft: '5px' }}>
                {ageGroup}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Pyramid;
