import React, { useState, useEffect, useRef } from 'react';
import Colors from '../utilities/Colors';
import { useGlobalHoveredId, useGlobalSelectedId, useGlobalActiveGeography } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';
interface DataItem {
  id: string;
  value: any;
}

export interface HistogramDynamicProps {
  dataArray: DataItem[];
  histMax: number;
  histNA: number;
  histLength: number;
  chartId: string;
  height: string;
  width: string;
  paddingLeft: string;
  paddingRight: string;
  tooltips: boolean;
  DESCRIPTOR: string;
}

export const HistogramDynamic: React.FC<HistogramDynamicProps> = ({
  dataArray, histMax, histNA, histLength, chartId, height, width, paddingRight, paddingLeft, tooltips, DESCRIPTOR
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);

  const hoveredId = useGlobalHoveredId();
  const selectedId = useGlobalSelectedId();
  const activeGeography = useGlobalActiveGeography();

  useEffect(() => {
    const updateContainerWidth = () => {
      if (svgRef.current) {
        setContainerWidth(svgRef.current.getBoundingClientRect().width);
      }
    };

    window.addEventListener('resize', updateContainerWidth);
    updateContainerWidth(); // Initial update

    return () => window.removeEventListener('resize', updateContainerWidth);
  }, [hoveredId, selectedId, activeGeography]);

  const viewBoxWidth = histLength * 10; // Assume each bar occupies "10" units in viewBox coordinates for consistency

  // Define the bar width in viewBox units for consistency
  const barWidthViewBoxUnits = dataArray
  ? dataArray.length > 263 
    ? 27 
    : 9
  : 27; // Adjust based on number of bars

  const barWidthAdjustment = dataArray
  ? dataArray.length > 263 
    ? 13 
    : 0
  : 13; // Adjust based on number of bars
  const _height = parseFloat(height);
  return (
    //console.log("A041824 dataArray", dataArray),
    dataArray && dataArray.length > 0 
    ? <>
    {dataArray.map((d, i) => {
        //console.log("D093024 d", d)
        const isSelected = d.id == selectedId;
        const isHovered = d.id == hoveredId;
        const fill = isSelected ? Colors.highlightSelect : isHovered ? Colors.highlightHover : 'none'; // 'none' for transparent or any default non-highlight color
        
        if (!isSelected && !isHovered) return null; // Skip rendering if not selected or hovered

        const barHeight = d.value === "NA" ? 0 : (d.value / histMax) * _height; // Adjusted for viewBox height of 80 units
        //console.log("H093024 dataArray", dataArray);
        //console.log("102224 dataArray.length", dataArray.length);
        //console.log("102224 containerWidth", containerWidth);
        //console.log("102224 activeGeography", activeGeography);
        //console.log("102224 i", i);
        return(
          <div
            key={`${isSelected ? 'selected' : 'hovered'}_${chartId}_${i}_box_hist_cont`}
            /*style={{
              paddingRight: paddingRight,
              paddingLeft: paddingLeft,
            }}*/
          >
            <div
              id={`${isSelected ? 'selected' : 'hovered'}HistogramTooltip`}
              className={`hist-label-rect ${isSelected ? 'popup-selected' : 'popup-hovered'}`}
              key={`${isSelected ? 'selected' : 'hovered'}_${chartId}_${i}_box_hist`}
              style={{
                width:"80px",
                height:"50px",
                backgroundColor: "#FAF5F5",
                border: `1px solid ${fill}`,
                color: `${fill}`,
                position: "absolute",
                fontSize: "12px",
                textAlign: "center",
                /*left: (activeGeography == "Boro") 
                  ? `calc(${(i * (100 / dataArray.length))}% - 3px` 
                  : `${(i * (containerWidth / dataArray.length)) - (activeGeography == "CD" 
                    ? 40
                    : 40)}px`,*/
                left: `calc(${(i * (100 / dataArray.length))}% - 40px + ${(containerWidth / dataArray.length) / 2}px - ${(activeGeography == "Boro") ? (containerWidth / dataArray.length) / 20 : 0}px)`,
                display: `${tooltips ? 'block' : 'none'}`,
                lineHeight: 1.25,
                paddingTop: activeGeography === "Tract" ? "0px" : "8.5px",
              }}
            >
            <div
              style={{
                  zIndex: 10,
                  position:"relative",
              }}
            >
              {`${activeGeography} ${d.id}`}<br/><b>{d2gRound(d.value, { DESCRIPTOR: DESCRIPTOR })}</b></div>
              <div
                  style={{
                    width:"16px",
                    height:"16px",
                    backgroundColor: "#FAF5F5",
                    border: `1px solid ${fill}`,
                    position: "absolute",
                    bottom:"-8.5px",
                    left:"calc(50% - 8px)",
                    rotate: "45deg",
                    borderTop:"none",
                    borderLeft:"none",
                    zIndex: 9,
                  }}
                ></div>
            </div>
            <svg 
              id={`${isSelected ? 'selected' : 'hovered'}HistogramSelectionBar`}
              key={`${isSelected ? 'selected' : 'hovered'}_${chartId}_${i}_svg_hist`}
              ref={svgRef} width={width} height={`${height}px`} viewBox={`0 0 ${viewBoxWidth} ${height}`} preserveAspectRatio="none" className='hist-container-dynamic'          
              style={{
                paddingRight: paddingRight,
                paddingLeft: paddingLeft,
              }}>
              <rect
                key={`${isSelected ? 'selected' : 'hovered'}_${chartId}_${i}_rect`}
                x={(i * 10) - barWidthAdjustment} // Each bar starts at 10 units apart
                y={_height - barHeight} // Adjust based on barHeight for bottom-up growth
                width={barWidthViewBoxUnits} // Consistent bar width
                height={barHeight}
                fill={fill}
                className="hist-bar"
              />
            </svg>
          </div>
      )})}
      </>
    : null
  );
};
