import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useGlobalSelectedId, onSetActiveIndicator } from '../../data/StatusStore';
import { useData } from '../../data/DataContext';
import Colors from '../utilities/Colors';
import { d2gRound } from '../utilities/Utilities';
//import { FeatureCollection } from 'geojson';
//import * as topojson from 'topojson-client';


interface GlobeMapProps {
  options: any;
  data: any;
  width: number;
  height: number;
  colorClass:string;
}

const GlobeMap: React.FC<GlobeMapProps> = ({ options, data, width, height, colorClass }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [preppedData, setPreppedData] = useState<any>([]);
  const [preppedDataJSON, setPreppedDataJSON] = useState<any>([]);
  const [maxCategoryValue, setMaxCategoryValue] = useState<number>(0);
  const [minCategoryValue, setMinCategoryValue] = useState<number>(0);
  const [hoveredCountry, setHoveredCountry] = useState<{ name: string, value: number, region: string, variable: string } | null>(null);
  const selectedId = useGlobalSelectedId();
  const { geoJsonDataGlobe, error } = useData();

  useEffect(() => {
    if (selectedId) {
      prepData();
    }
  }, [selectedId]);

  const prepData = () => {
    if (selectedId) {
      console.log("E061024 map options.variables", options.variables);
      const _filteredData = options.variables.filter((v: any) => v.sort.Tooltip_only === "");
      const _tooltipData = options.variables.filter((v: any) => {
        if (v.sort.Tooltip_only !== "") {
          return { v };
        }
      });
      console.log("E061024 _filteredData", _filteredData);
      console.log("E061024 _tooltipData", _tooltipData);
      const _preppedData = _filteredData.map((v: any) => ({
        name: v.label,
        value: data.dataJSON[selectedId][v.variable],
        category: v.sort['Sums to 100'],
        variable: v.variable,
      }));
      console.log("E061024 globe _preppedData", _preppedData);
      setPreppedData(_preppedData);
      setPreppedDataJSON(transformArrayToJson(_preppedData, 'variable'));
      setMaxCategoryValue(Math.max(..._preppedData.map((d: any) => d.value)));
      setMinCategoryValue(Math.min(..._preppedData.map((d: any) => d.value)));
    }
  };

  const transformArrayToJson = (array: Array<{ [key: string]: any }>, key: string): { [key: string]: any } => {
    return array.reduce((acc, item) => {
      if (item[key]) {
        acc[item[key]] = item;
      }
      return acc;
    }, {} as { [key: string]: any });
  };

  const onClicked = (variable: string) => {
    console.log('Clicked globe variable:', variable);
    onSetActiveIndicator(variable);
  };

  useEffect(() => {
    console.log("E061024 geoJsonDataGlobe", geoJsonDataGlobe);
    console.log("E061024 options", options);
    if (!geoJsonDataGlobe) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    const projection = d3.geoNaturalEarth1();

    // Automatically fit the projection within the SVG based on both width and height
    projection.fitExtent(
      [
        [0, 0],
        [width, height],
      ],
      geoJsonDataGlobe
    );

    const path = d3.geoPath().projection(projection);

    svg.append('g')
      .selectAll('path')
      .data(geoJsonDataGlobe.features)
      .enter()
      .append('path')
      .attr('class', 'country')
      .attr('d', path)
      .attr('fill', (d: any) => {
        const color = preppedDataJSON[d.properties.mapdata_fo]
          ? Colors.getColorQuintile(preppedDataJSON[d.properties.mapdata_fo].value, minCategoryValue, maxCategoryValue, colorClass)
          : (d.properties.mapdata_fo === "antarctica_0" ? "#fff" : "#ccc");
        return color;
      })
      .attr('stroke', "#fff")
      .attr('stroke-width', 0.25)
      .style('cursor', 'pointer')
      .on('click', (event: any, d: any) => {
        console.log('Clicked d', d);
        console.log('Clicked event', event);
        if (d.properties.mapdata_fo) {
          onClicked(d.properties.mapdata_fo);
        }
        if (preppedDataJSON[d.properties.mapdata_fo]) {
          console.log("071324 pd.properties", d.properties)
          console.log("071324 preppedDataJSON[d.properties.mapdata_fo]", preppedDataJSON[d.properties.mapdata_fo])
          setHoveredCountry({
            name: d.properties.name,
            value: preppedDataJSON[d.properties.mapdata_fo].value,
            region: preppedDataJSON[d.properties.mapdata_fo].name,
            variable: d.properties.mapdata_fo,
          });
        }
      })
      .on('mouseover', (event: any, d: any) => {
        if (preppedDataJSON[d.properties.mapdata_fo]) {
          console.log("071324 pd.properties", d.properties)
          console.log("071324 preppedDataJSON[d.properties.mapdata_fo]", preppedDataJSON[d.properties.mapdata_fo])
          setHoveredCountry({
            name: d.properties.name,
            value: preppedDataJSON[d.properties.mapdata_fo].value,
            region: preppedDataJSON[d.properties.mapdata_fo].name,
            variable: d.properties.mapdata_fo,
          });
        }
      })
      .on('mouseout', () => {
        //setHoveredCountry(null);
      });
  }, [geoJsonDataGlobe, preppedDataJSON, width, height]);

  const fontSize = 12; // For dynamic heights --> // Math.min(12, Math.max(8, width / 50));
  const fontSize2 = 12; // For dynamic heights --> // Math.min(10, Math.max(8, width / 50));
  const chartSize = Math.min(width / 2, height / 2);
  const labelWidth = "250px"; //`calc(40% - ${((chartSize + 10))}px)`;
  const chartWidth = `calc(100% - 250px)`; // `calc(60% + ${((chartSize + 10))}px)`;

  // Adjusting the viewBox to clip 5% off the left and right sides
  const adjustedViewBoxX = width * 0.05;  // 5% from left
  const adjustedViewBoxWidth = width * 0.9;  // Reduce width by 10%

  return (
    <div style={{ width: '100%', height: height, position: 'relative', display: 'flex', alignItems: 'flex-start', paddingRight:"16px" }}>
      <div 
        style={{ 
          marginLeft: '0px', 
          marginRight: '0px', 
          fontSize: fontSize2, 
          lineHeight: `${fontSize + 2 }px`, 
          width: labelWidth,
          height:"100%",
        }}>
        {hoveredCountry ? (
          <div 
            style={{ 
              fontSize: fontSize2,
              lineHeight: `${fontSize + 2 }px`, 
              width:"100%", 
            }}>
            <div style={{ fontWeight: 'bold', textAlign:'start', fontSize:'24px', paddingBottom: 15, width:'100%' }}>{d2gRound(hoveredCountry.value, { DESCRIPTOR: data.meta["DESCRIPTOR"][hoveredCountry.variable] })}</div>
            <div style={{ width: '100%'}}>
              <span>{hoveredCountry.region.replace("Foreign-Born, ", "").replace(" (% of all foreign-born)", "")}</span>
              <span>  </span>
              <span style={{ color:"#9e9c9c"}}>({hoveredCountry.name})</span>
            </div>
          </div>
        ) : (
          <div style={{ fontSize: fontSize2 }}>Hover over a country to see details</div>
        )}
      </div>
      <div 
        style={{ 
          fontSize, 
          lineHeight: '1', 
          display: 'flex', 
          flexDirection: 'column', 
          width: chartWidth, 
          height:"100%",
          position: "relative", 
          justifyContent: 'center', 
          alignItems: 'center' 
        }}>
        <svg 
          ref={svgRef} 
          style={{ width: "100%", height: "100%" }} 
          viewBox={`${adjustedViewBoxX} 0 ${adjustedViewBoxWidth} ${height}`} 
          preserveAspectRatio="xMidYMid meet"
        ></svg>
      </div>
    </div>
  );
};

export default GlobeMap;
