import { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

type Subscriber<T> = (value: T) => void;

class StatusStore {
  // INSTANTIATE VARIABLES
  private hoveredId: string | null = null;
  private selectedId: string | null = null;
  private hoveredSubscribers: Subscriber<string | null>[] = [];
  private selectedSubscribers: Subscriber<string | null>[] = [];
  private activeGeography: string = new URLSearchParams(window.location.search).get('activeGeography') || "CD"; // Default value set
  private activeIndicator: string = new URLSearchParams(window.location.search).get('activeIndicator') || "adult_samehome";
  private cachedActiveIndicators: any = {};
  private cachedSelectedIds: any = {
    Boro: "1",
    CD: "101",
    PUMA: "",
    NTA: "MN0802",
    Tract: "36047028600"
  };
  private bivariateIndicator: string = new URLSearchParams(window.location.search).get('bivariateIndicator') || "aframer_pop";
  private bivariateOverRide: { x: number[], y: number[] } | null = null;
  private moduleId: string = new URLSearchParams(window.location.search).get('moduleId') || 'ahdi';
  private DBModuleId: string = new URLSearchParams(window.location.search).get('DBModuleId') || 'ahdi';
  private activeCategory: string = "Basics";
  private activeSubCategory: string = "American Human Development Index";
  private categoryChangeComplete: boolean = false;
  private activeGeographySubscribers: Subscriber<string>[] = [];
  private activeIndicatorSubscribers: Subscriber<string>[] = [];
  private cachedActiveIndicatorsSubscribers: Subscriber<any>[] = [];
  private cachedSelectedIdsSubscribers: Subscriber<any>[] = [];
  private bivariateIndicatorSubscribers: Subscriber<string>[] = [];
  private bivariateOverRideSubscribers: Subscriber<{ x: number[], y: number[] } | null>[] = [];
  private activeCategorySubscribers: Subscriber<string>[] = [];
  private moduleIdSubscribers: Subscriber<string>[] = [];
  private DBModuleIdSubscribers: Subscriber<string>[] = [];
  private activeSubCategorySubscribers: Subscriber<string>[] = [];
  private categoryChangeCompleteSubscribers: Subscriber<boolean>[] = [];

  private navigate: (url: string, options?: { replace?: boolean }) => void;
  private location: ReturnType<typeof useLocation>;

  constructor() {
    this.navigate = () => {};
    this.location = { search: '' } as any;
  }

  // Initialize the store with navigation and location context from React Router
  initializeNavigation(
    navigate: (url: string, options?: { replace?: boolean }) => void,
    location: ReturnType<typeof useLocation>
  ) {
    this.navigate = navigate;
    this.location = location;

    // Initialize from URL
    const searchParams = new URLSearchParams(location.search);
    this.activeGeography = searchParams.get('activeGeography') || this.activeGeography;
    this.activeGeographySubscribers.forEach((sub) => sub(this.activeGeography));

    this.selectedId = searchParams.get('selectedId') || this.cachedSelectedIds[this.activeGeography] || this.selectedId;
    this.selectedSubscribers.forEach((sub) => sub(this.selectedId));

    this.activeIndicator = searchParams.get('activeIndicator') || this.activeIndicator || this.cachedActiveIndicators[this.activeGeography].active;
    this.activeIndicatorSubscribers.forEach((sub) => sub(this.activeIndicator));
    
  }

  // Utility function to create ordered query string
  /*private getOrderedQueryString(_searchParams:URLSearchParams) {
    console.log("091624 getOrderedQueryString() _searchParams");
    const paramsOrder = ['page', 'lat', 'lng', 'zoom', 'selectedId', 'activeGeography'];
    const searchParams = _searchParams;//new URLSearchParams(this.location.search);
    const orderedParams = new URLSearchParams();

    paramsOrder.forEach(param => {
      console.log("091624 param", param);
      const value = searchParams.get(param);
      if (value) orderedParams.set(param, value);
    });

    // Add any remaining parameters not in the default order
    Array.from(searchParams.keys()).forEach(key => {
      if (!paramsOrder.includes(key)) {
        console.log("B091624 key", key);
        const value = searchParams.get(key);
        console.log("B091624 value", value);
        if (value) orderedParams.set(key, value);
      }
    });
    console.log("B091624 orderedParams", orderedParams);
    return orderedParams.toString();
  }*/
 
  // Update URL parameters in specified order
  private updateUrlParams(caller?: string) {
    const searchParams = new URLSearchParams(this.location.search);
    // Ensure all parameters are updated correctly
    searchParams.set('page', searchParams.get('page') || 'explorer'); // Always set page
    if (this.selectedId !== null) searchParams.set('selectedId', this.selectedId); 
    if (this.activeGeography) searchParams.set('activeGeography', this.activeGeography);
    if (this.moduleId) searchParams.set('moduleId', this.moduleId.toString());
    if (this.DBModuleId) searchParams.set('DBModuleId', this.DBModuleId.toString());
    console.log("D101624 caller", caller);
    console.log("D101624 this.activeIndicator", this.activeIndicator);
    console.log("D101624 this.bivariateIndicator", this.bivariateIndicator);
    if (this.activeIndicator) searchParams.set('activeIndicator', this.activeIndicator.toString());
    if (this.bivariateIndicator) searchParams.set('bivariateIndicator', this.bivariateIndicator.toString());
    
    const lat = searchParams.get('lat');
    const lng = searchParams.get('lng');
    const zoom = searchParams.get('zoom');

    if (lat) searchParams.set('lat', lat);
    if (lng) searchParams.set('lng', lng);
    if (zoom) searchParams.set('zoom', zoom);

    // Construct the ordered query string and navigate
    const orderedQueryString = getOrderedQueryString(searchParams);
    this.navigate(`/?${orderedQueryString}`, { replace: true });
  }

  // DEFINE METHODS
  setHoveredId = (id: string | null) => {
    this.hoveredId = id;
    this.hoveredSubscribers.forEach((sub) => sub(this.hoveredId));
  };

  setSelectedId = (id: string | null) => {
    this.selectedId = id;
    this.selectedSubscribers.forEach((sub) => sub(this.selectedId));
    this.updateUrlParams("setSelectedId"); // Update URL on change
  };

  setActiveGeography = (geography: string) => {
    console.log("060524 this.activeGeography", this.activeGeography);
    console.log("060524 this.cachedSelectedIds", this.cachedSelectedIds);
    console.log("060524 this.cachedActiveIndicators", this.cachedActiveIndicators);
    console.log("060524 this.cachedSelectedIds[geography]", this.cachedSelectedIds[geography]);

    if (this.activeGeography && this.selectedId !== null) {
      this.cachedSelectedIds[this.activeGeography] = this.selectedId;
    }

    this.activeGeography = geography;
    this.activeGeographySubscribers.forEach((sub) => sub(this.activeGeography));

    const newSelectedId = this.cachedSelectedIds[geography] || null;
    this.setSelectedId(newSelectedId);

    this.updateUrlParams("setActiveGeography"); // Update URL on change
  };

  setActiveCategory = (category: string) => {
    this.activeCategory = category;
    this.activeCategorySubscribers.forEach((sub) => sub(this.activeCategory));
  };

  setModuleId = (id: string) => {
    this.moduleId = id;
    this.moduleIdSubscribers.forEach((sub) => sub(this.moduleId));
    this.updateUrlParams("setModuleId"); 
  };

  setDBModuleId = (id: string) => {
    this.DBModuleId = id;
    this.DBModuleIdSubscribers.forEach((sub) => sub(this.DBModuleId));
    this.updateUrlParams("setDBModuleId"); 
  };

  setActiveSubCategory = (subCategory: string) => {
    this.activeSubCategory = subCategory;
    this.activeSubCategorySubscribers.forEach((sub) => sub(this.activeSubCategory));
  };

  setCategoryChangeComplete = (setting: boolean) => {
    this.categoryChangeComplete = setting;
    this.categoryChangeCompleteSubscribers.forEach((sub) =>
      sub(this.categoryChangeComplete)
    );
  };

  setActiveIndicator = (indicator: string) => {
    this.activeIndicator = indicator;
    this.activeIndicatorSubscribers.forEach((sub) => sub(this.activeIndicator));
    this.updateUrlParams("setActiveIndicator"); // Update URL on change
  };

  setCachedActiveIndicators = (indicators: any) => {
    this.cachedActiveIndicators = indicators;
    this.cachedActiveIndicatorsSubscribers.forEach((sub: any) =>
      sub(this.cachedActiveIndicators)
    );
  };

  setCachedSelectedIds = (selectedIds: any) => {
    this.cachedSelectedIds = selectedIds;
    this.cachedSelectedIdsSubscribers.forEach((sub: any) =>
      sub(this.cachedSelectedIds)
    );
  };

  setBivariateIndicator = (indicator: string) => {
    this.bivariateIndicator = indicator;
    this.bivariateIndicatorSubscribers.forEach((sub) => sub(this.bivariateIndicator));
    this.updateUrlParams("setBivariateIndicator");
  };

  setBivariateOverRide = (override: { x: number[], y: number[] } | null) => {
    this.bivariateOverRide = override;
    this.bivariateOverRideSubscribers.forEach((sub) => sub(this.bivariateOverRide));
  };

  // SUBSCRIBE TO CHANGES
  subscribeToHoveredId = (subscriber: Subscriber<string | null>) => {
    this.hoveredSubscribers.push(subscriber);
    return () => {
      this.hoveredSubscribers = this.hoveredSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToSelectedId = (subscriber: Subscriber<string | null>) => {
    this.selectedSubscribers.push(subscriber);
    return () => {
      this.selectedSubscribers = this.selectedSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToActiveGeography = (subscriber: Subscriber<string>) => {
    this.activeGeographySubscribers.push(subscriber);
    return () => {
      this.activeGeographySubscribers = this.activeGeographySubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToActiveCategory = (subscriber: Subscriber<string>) => {
    this.activeCategorySubscribers.push(subscriber);
    return () => {
      this.activeCategorySubscribers = this.activeCategorySubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToModuleId = (subscriber: Subscriber<string>) => {
    this.moduleIdSubscribers.push(subscriber);
    return () => {
      this.moduleIdSubscribers = this.moduleIdSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToDBModuleId = (subscriber: Subscriber<string>) => {
    this.DBModuleIdSubscribers.push(subscriber);
    return () => {
      this.DBModuleIdSubscribers = this.DBModuleIdSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToActiveSubCategory = (subscriber: Subscriber<string>) => {
    this.activeSubCategorySubscribers.push(subscriber);
    return () => {
      this.activeSubCategorySubscribers = this.activeSubCategorySubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToCategoryChangeComplete = (subscriber: Subscriber<boolean>) => {
    this.categoryChangeCompleteSubscribers.push(subscriber);
    return () => {
      this.categoryChangeCompleteSubscribers =
        this.categoryChangeCompleteSubscribers.filter((sub) => sub !== subscriber);
    };
  };

  subscribeToActiveIndicator = (subscriber: Subscriber<string>) => {
    this.activeIndicatorSubscribers.push(subscriber);
    return () => {
      this.activeIndicatorSubscribers = this.activeIndicatorSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToCachedActiveIndicators = (subscriber: Subscriber<string>) => {
    this.cachedActiveIndicatorsSubscribers.push(subscriber);
    return () => {
      this.cachedActiveIndicatorsSubscribers =
        this.cachedActiveIndicatorsSubscribers.filter((sub: any) => sub !== subscriber);
    };
  };

  subscribeToCachedSelectedIds = (subscriber: Subscriber<string>) => {
    this.cachedSelectedIdsSubscribers.push(subscriber);
    return () => {
      this.cachedSelectedIdsSubscribers = this.cachedSelectedIdsSubscribers.filter(
        (sub: any) => sub !== subscriber
      );
    };
  };

  subscribeToBivariateIndicator = (subscriber: Subscriber<string>) => {
    this.bivariateIndicatorSubscribers.push(subscriber);
    return () => {
      this.bivariateIndicatorSubscribers = this.bivariateIndicatorSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToBivariateOverRide = (subscriber: Subscriber<{ x: number[], y: number[] } | null>) => {
    this.bivariateOverRideSubscribers.push(subscriber);
    return () => {
      this.bivariateOverRideSubscribers = this.bivariateOverRideSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  // GETTERS
  getOnHoverHistogram = () =>
    debounce((id: string | null) => {
      this.setHoveredId(id);
    }, 10);

  getOnClickHistogram = () => (id: string | null) => {
    this.setSelectedId(id);
  };

  getOnChangeActiveGeography = () => (geography: string) => {
    console.log("060524 this.activeGeography", this.activeGeography);
    console.log("060524 this.cachedSelectedIds", this.cachedSelectedIds);
    console.log("060524 this.cachedActiveIndicators", this.cachedActiveIndicators);
    console.log("060524 this.cachedSelectedIds[geography]", this.cachedSelectedIds[geography]);
    this.setActiveGeography(geography);
  };

  getOnChangeActiveIndicator = () =>
    debounce((indicator: string) => {
      this.setActiveIndicator(indicator);
    }, 50);

  getOnChangeActiveCategory = () =>
    debounce((category: string) => {
      this.setActiveCategory(category);
    }, 50);

  getOnChangeModuleId = () =>
    debounce((id: string) => {
      this.setModuleId(id);
    }, 50);

  getOnChangeDBModuleId = () =>
    debounce((id: string) => {
      this.setDBModuleId(id);
    }, 50);

  getOnChangeActiveSubCategory = () =>
    debounce((subCategory: string) => {
      this.setActiveSubCategory(subCategory);
    }, 50);

  getOnChangeCategoryChangeComplete = () =>
    debounce((setting: boolean) => {
      this.setCategoryChangeComplete(setting);
    }, 50);

  getOnChangeCachedActiveIndicators = () =>
    debounce((indicators: any) => {
      this.setCachedActiveIndicators(indicators);
    }, 50);

  getOnChangeCachedSelectedIds = () =>
    debounce((ids: any) => {
      this.setCachedSelectedIds(ids);
    }, 50);

  getOnChangeBivariateIndicator = () =>
    debounce((indicator: string) => {
      this.setBivariateIndicator(indicator);
    }, 50);

  getOnChangeBivariateOverRide = () =>
    debounce((override: { x: number[], y: number[] } | null) => {
      this.setBivariateOverRide(override);
    }, 50);

  getOnSetSelectedId = () => (id: string | null) => {
    console.log("060524 ------------> getOnSetSelectedId id", id);
    this.setSelectedId(id);
  };
}

// INSTANTIATE THE STORE
const statusStore = new StatusStore();

// Utility function to create ordered query string
export const getOrderedQueryString = (_searchParams: URLSearchParams) => {
  console.log("091624 getOrderedQueryString() _searchParams");
  const paramsOrder = ['page', 'lat', 'lng', 'zoom', 'selectedId', 'activeGeography'];
  const searchParams = _searchParams;
  const orderedParams = new URLSearchParams();

  paramsOrder.forEach(param => {
    //console.log("091624 param", param);
    const value = searchParams.get(param);
    if (value) orderedParams.set(param, value);
  });

  // Add any remaining parameters not in the default order
  Array.from(searchParams.keys()).forEach(key => {
    if (!paramsOrder.includes(key)) {
      //console.log("B091624 key", key);
      const value = searchParams.get(key);
      //console.log("B091624 value", value);
      if (value) orderedParams.set(key, value);
    }
  });
  console.log("B091624 orderedParams", orderedParams);
  return orderedParams.toString();
};

// EXPORT THE STORE
export const useGlobalHoveredId = () => {
  const [hoveredId, setHoveredId] = useState<string | null>(null);
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToHoveredId(setHoveredId);
    return unsubscribe;
  }, []);

  return hoveredId;
};

export const useGlobalSelectedId = () => {
  const [selectedId, setSelectedId] = useState<string | null>(null);
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToSelectedId(setSelectedId);
    return unsubscribe;
  }, []);

  return selectedId;
};

export const useGlobalActiveGeography = () => {
  const [activeGeography, setActiveGeography] = useState<string>(new URLSearchParams(window.location.search).get('activeGeography') || "CD");
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToActiveGeography(setActiveGeography);
    return unsubscribe;
  }, []);

  return activeGeography;
};

export const useGlobalActiveIndicator = () => {
  const [activeIndicator, setActiveIndicator] = useState<string>(new URLSearchParams(window.location.search).get('activeIndicator') || "adult_samehome");
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToActiveIndicator(setActiveIndicator);
    return unsubscribe;
  }, []);

  return activeIndicator;
};

export const useGlobalActiveCategory = () => {
  const [activeCategory, setActiveCategory] = useState<string>("Basics");
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToActiveCategory(setActiveCategory);
    return unsubscribe;
  }, []);

  return activeCategory;
};

export const useGlobalModuleId = () => {
  const [moduleId, setModuleId] = useState<string>(new URLSearchParams(window.location.search).get('moduleId') || 'ahdi');
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToModuleId(setModuleId);
    return unsubscribe;
  }, []);

  return moduleId;
};

export const useGlobalDBModuleId = () => {
  const [DBModuleId, setDBModuleId] = useState<string>(new URLSearchParams(window.location.search).get('DBModuleId') || 'ahdi');
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToDBModuleId(setDBModuleId);
    return unsubscribe;
  }, []);

  return DBModuleId;
};

export const useGlobalActiveSubCategory = () => {
  const [activeSubCategory, setActiveSubCategory] = useState<string>(
    "American Human Development Index"
  );
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToActiveSubCategory(setActiveSubCategory);
    return unsubscribe;
  }, []);

  return activeSubCategory;
};

export const useCategoryChangeComplete = () => {
  const [categoryChangeComplete, setCategoryChangeComplete] = useState<boolean>(false);
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToCategoryChangeComplete(
      setCategoryChangeComplete
    );
    return unsubscribe;
  }, []);

  return categoryChangeComplete;
};

export const useGlobalCachedActiveIndicators = () => {
  const [cachedActiveIndicators, setCachedActiveIndicators] = useState<any>({
    Tract: {
      active: "adult_samehome",
      bivariate: "aframer_pop"
    },
    CD: {
      active: "adult_samehome",
      bivariate: "aframer_pop"
    },
    NTA: {
      active: "adult_samehome",
      bivariate: "aframer_pop"
    },
    Boro: {
      active: "adult_samehome",
      bivariate: "aframer_pop"
    }
  });
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToCachedActiveIndicators(
      setCachedActiveIndicators
    );
    return unsubscribe;
  }, []);

  return cachedActiveIndicators;
};

export const useGlobalCachedSelectedIds = () => {
  const [cachedSelectedIds, setCachedSelectedIds] = useState<any>({
    Boro: "1",
    CD: "101",
    PUMA: "",
    NTA: "MN0802",
    Tract: "36047028600"
  });
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToCachedSelectedIds(setCachedSelectedIds);
    return unsubscribe;
  }, []);

  return cachedSelectedIds;
};

export const useGlobalBivariateIndicator = () => {
  const [bivariateIndicator, setBivariateIndicator] = useState<string>(new URLSearchParams(window.location.search).get('bivariateIndicator') || "aframer_pop");
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToBivariateIndicator(setBivariateIndicator);
    return unsubscribe;
  }, []);

  return bivariateIndicator;
};

export const useGlobalBivariateOverRide = () => {
  const [bivariateOverRide, setBivariateOverRide] = useState<{ x: number[]; y: number[] } | null>(
    null
  );
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToBivariateOverRide(setBivariateOverRide);
    return unsubscribe;
  }, []);

  return bivariateOverRide;
};

// EXPORT THE STORE METHODS
export const onHoverHistogram = statusStore.getOnHoverHistogram();
export const onClickHistogram = statusStore.getOnClickHistogram();
export const onSetActiveGeography = statusStore.getOnChangeActiveGeography();
export const onSetActiveIndicator = statusStore.getOnChangeActiveIndicator();
export const onSetActiveCategory = statusStore.getOnChangeActiveCategory();
export const onSetModuleId = statusStore.getOnChangeModuleId();
export const onSetDBModuleId = statusStore.getOnChangeDBModuleId();
export const onSetActiveSubCategory = statusStore.getOnChangeActiveSubCategory();
export const onSetCategoryChangeComplete = statusStore.getOnChangeCategoryChangeComplete();
export const onSetCachedActiveIndicators = statusStore.getOnChangeCachedActiveIndicators();
export const onSetCachedSelectedIds = statusStore.getOnChangeCachedSelectedIds();
export const onSetBivariateIndicator = statusStore.getOnChangeBivariateIndicator();
export const onSetBivariateOverRide = statusStore.getOnChangeBivariateOverRide();
export const onSetSelectedId = statusStore.getOnSetSelectedId();

// EXPORT THE HOOK TO INITIALIZE NAVIGATION CONTEXT
export const useStatusStoreNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    statusStore.initializeNavigation(navigate, location);
  }, [navigate, location]);
};
