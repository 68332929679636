import React from 'react';
import * as ss from 'simple-statistics';
import { jStat } from 'jstat';

// Function to calculate t-distribution CDF
function tDistributionCdf(t: number, df: number): number {
  return jStat.studentt.cdf(t, df);
}

// Helper function to calculate Pearson correlation coefficient and p-value
function pearsonrPval(x: number[], y: number[]): { r: number; p: number } {
  const r = ss.sampleCorrelation(x, y);
  const n = x.length;
  const t = Math.abs(r) * Math.sqrt((n - 2) / (1 - r ** 2));
  const p = 2 * (1 - tDistributionCdf(t, n - 2));
  return { r, p };
}

interface CorrelationCardProps {
  dataArrayX: Record<number, { value: number | null; index: number }>; // Allow null for missing values
  dataArrayY: Record<number, { value: number | null; index: number }>;
  xLabel: string;
  yLabel: string;
}

const CorrelationCard: React.FC<CorrelationCardProps> = ({ dataArrayX, dataArrayY, xLabel, yLabel }) => {
  // Filter out entries where either x or y is null or NaN
  const validData = Object.values(dataArrayX)
    .map((item, i) => ({
      x: item.value,
      y: Object.values(dataArrayY)[i]?.value,
    }))
    .filter(({ x, y }) => x !== null && y !== null && !isNaN(x) && !isNaN(y));

  const xValues = validData.map((item) => item.x as number);
  const yValues = validData.map((item) => item.y as number);

  if (xValues.length !== yValues.length || xValues.length === 0) {
    return <p>Error: Both datasets must have the same length and contain valid data points.</p>;
  }

  const { r, p } = pearsonrPval(xValues, yValues);

  // Determine strength of correlation
  const strength =
    Math.abs(r) < 0.3
      ? 'weak'
      : Math.abs(r) < 0.6
      ? 'moderate'
      : 'strong';

  // Reliability checks
  const rReliable = Math.abs(r) >= 0.25 ? 'ok' : 'not significant';
  const pReliable = p < 0.05 ? 'ok' : 'not significant';

  // Direction of correlation
  const direction = r < 0 ? 'decreases' : 'increases';

  // Summary sentence
  const summary =
    rReliable === 'ok' && pReliable === 'ok'
      ? `As ${xLabel.split('(')[0]} increases, ${yLabel.split('(')[0]} ${direction}. There is a ${strength} linear relationship between these two variables.`
      : `The linear relationship between these two variables is not statistically significant.`;

  return (
    <div>
      <h3>Correlation Analysis:</h3>
      <p>
        <strong>Correlation Coefficient (r):</strong> {r.toFixed(8)}
      </p>
      <p>
        <strong>P-Value (p):</strong> {p.toFixed(8)}
      </p>
      <h4>Summary:</h4>
      <p>{summary}</p>
    </div>
  );
};

export default CorrelationCard;
