interface D2gRoundOptions {
    currency?: boolean;
    override?: [boolean, number];
    countDecimalOverride?: number;
    returnString?: boolean;
    DESCRIPTOR?: string;
}

export const d2gRound = (
    number: number,
    options: D2gRoundOptions = {}
): string | number => {
    // Destructure options with default values
    //console.log("D101124 number", number);
    if (number === null || isNaN(number) || number === undefined) {
        return "N/A";
    }
    let countDecimal = 1;
    let isMoney = false;
    let isPct = false;
    if (options.DESCRIPTOR){
        let str = options.DESCRIPTOR;
        countDecimal = (str.match(/#/g) || []).length;
        isMoney = str.includes("$");
        isPct = str.includes("%");
        //return options.DESCRIPTOR;
    }
    if (options.countDecimalOverride) {
        countDecimal = options.countDecimalOverride;
    }
    /* OLD LOGIC (based on LandScan)
    const {
        currency = false,
        override = [false, 1],
        returnString = false
    } = options;*/

    let rounded: number | string;

    if (typeof number === 'number') {
        rounded = number;
    } else {
        rounded = parseFloat(number);
    }

    rounded = parseFloat(rounded.toFixed(countDecimal));
    
    /* OLD LOGIC (based on LandScan) will sniff out the best number of decimal places to show
    if (override[0]) {
        rounded = parseFloat(rounded.toFixed(override[1]));
    } else if (currency) {
        if (returnString) {
            rounded = rounded.toFixed(2);
        } else {
            rounded = parseFloat(rounded.toFixed(2));
        }
    } else if (Math.abs(rounded) < 10) {
        rounded = parseFloat(rounded.toFixed(2));
    } else if (Math.abs(rounded) < 100) {
        rounded = parseFloat(rounded.toFixed(1));
    } else {
        if (returnString) {
            rounded = Math.round(rounded).toLocaleString(undefined, { maximumFractionDigits: 0 });
        } else {
            rounded = Math.round(rounded);
        }
    }*/

    if (options.returnString) {
        rounded = rounded.toLocaleString().toString();
    }
    if (isMoney) {
        //console.log("E101124 rounded", rounded);
        rounded = `$${rounded.toLocaleString().toString()}`;
    } else if (isPct) {
        rounded = `${rounded.toLocaleString().toString()}%`;
    } else {
        rounded = rounded.toLocaleString().toString();
    }

    return rounded;
}

export function getInvertedBivariateColorBreaks(
    maxX: number, minX: number, recordCountX: number, dataX: any,
    maxY: number, minY: number, recordCountY: number, dataY: any,
    allData: any, bivariateIndicator: string, activeIndicator: string, 
  ) {
    console.log("D070824 recordCountX, minX", recordCountX, minX);
    console.log("D070824 recordCountY, minY", recordCountY, minY);
    let xInd = bivariateIndicator;
    let yInd = activeIndicator;
    recordCountX = recordCountX - minX;
    recordCountY = recordCountY - minY;
  
    // Calculate the quantiles for the breaks
    const _quantileX1 = allData.dataArray[xInd][Math.ceil(recordCountX / 3)];
    const _quantileX2 = allData.dataArray[xInd][Math.ceil((recordCountX / 3) * 2)];
    const _quantileY1 = allData.dataArray[yInd][Math.ceil(recordCountY / 3)];
    const _quantileY2 = allData.dataArray[yInd][Math.ceil((recordCountY / 3) * 2)];

    console.log("D070824 recordCountX", recordCountX);
    console.log("D070824 recordCountY", recordCountY);
    console.log("D070824 allData.dataArray[yInd]", allData.dataArray[xInd]);
    console.log("D070824 allData.dataArray[yInd]", allData.dataArray[yInd]);
    console.log("D070824 _quantileX1", _quantileX1);
    console.log("D070824 _quantileX2", _quantileX2);
    console.log("D070824 _quantileY1", _quantileY1);
    console.log("D070824 _quantileY2", _quantileY2);

    return {
      xBreaks: [_quantileX1?.value ? _quantileX1?.value : null, _quantileX2?.value ? _quantileX2?.value : null], // Breaks for the X axis (value)
      yBreaks: [_quantileY1?.value ? _quantileY1?.value : null, _quantileY2?.value ? _quantileY2?.value : null]  // Breaks for the Y axis (value2)
    };
  }

const PUMA_to_CD: { [key: string]: string[] } = {
'3604103':['103'],
'3604104':['104'],
'3604107':['107'],
'3604108':['108'],
'3604109':['109'],
'3604110':['110'],
'3604111':['111'],
'3604112':['112'],
'3604121':['101','102'],
'3604165':['105','106'],
'3604204':['204'],
'3604205':['205'],
'3604207':['207'],
'3604208':['208'],
'3604209':['209'],
'3604210':['210'],
'3604211':['211'],
'3604212':['212'],
'3604221':['201','202'],
'3604263':['203','206'],
'3604301':['301'],
'3604302':['302'],
'3604303':['303'],
'3604304':['304'],
'3604305':['305'],
'3604306':['306'],
'3604307':['307'],
'3604308':['308'],
'3604309':['309'],
'3604310':['310'],
'3604311':['311'],
'3604312':['312'],
'3604313':['313'],
'3604314':['314'],
'3604315':['315'],
'3604316':['316'],
'3604317':['317'],
'3604318':['318'],
'3604401':['401'],
'3604402':['402'],
'3604403':['403'],
'3604404':['404'],
'3604405':['405'],
'3604406':['406'],
'3604407':['407'],
'3604408':['408'],
'3604409':['409'],
'3604410':['410'],
'3604411':['411'],
'3604412':['412'],
'3604413':['413'],
'3604414':['414'],
'3604501':['501'],
'3604502':['502'],
'3604503':['503'],
}

const CD_to_PUMA = {
'103':'3604103',
'104':'3604104',
'107':'3604107',
'108':'3604108',
'109':'3604109',
'110':'3604110',
'111':'3604111',
'112':'3604112',
'101':'3604121',
'102':'3604121',
'105':'3604165',
'106':'3604165',
'204':'3604204',
'205':'3604205',
'207':'3604207',
'208':'3604208',
'209':'3604209',
'210':'3604210',
'211':'3604211',
'212':'3604212',
'201':'3604221',
'202':'3604221',
'203':'3604263',
'206':'3604263',
'301':'3604301',
'302':'3604302',
'303':'3604303',
'304':'3604304',
'305':'3604305',
'306':'3604306',
'307':'3604307',
'308':'3604308',
'309':'3604309',
'310':'3604310',
'311':'3604311',
'312':'3604312',
'313':'3604313',
'314':'3604314',
'315':'3604315',
'316':'3604316',
'317':'3604317',
'318':'3604318',
'401':'3604401',
'402':'3604402',
'403':'3604403',
'404':'3604404',
'405':'3604405',
'406':'3604406',
'407':'3604407',
'408':'3604408',
'409':'3604409',
'410':'3604410',
'411':'3604411',
'412':'3604412',
'413':'3604413',
'414':'3604414',
'501':'3604501',
'502':'3604502',
'503':'3604503',
}

const NTA_to_CD: { [key: string]: string } = {
'BK0101':'301',
'BK0102':'301',
'BK0103':'301',
'BK0104':'301',
'BK0201':'302',
'BK0202':'302',
'BK0203':'302',
'BK0204':'302',
'BK0261':'302',
'BK0301':'303',
'BK0302':'303',
'BK0401':'304',
'BK0402':'304',
'BK0471':'304',
'BK0501':'305',
'BK0502':'305',
'BK0503':'305',
'BK0504':'305',
'BK0505':'305',
'BK0571':'305',
'BK0601':'306',
'BK0602':'306',
'BK0701':'307',
'BK0702':'307',
'BK0703':'307',
'BK0771':'307',
'BK0801':'308',
'BK0802':'308',
'BK0891':'308',
'BK0901':'309',
'BK0902':'309',
'BK1001':'310',
'BK1002':'310',
'BK1061':'310',
'BK1091':'310',
'BK1101':'311',
'BK1102':'311',
'BK1103':'311',
'BK1201':'312',
'BK1202':'312',
'BK1203':'312',
'BK1204':'312',
'BK1301':'313',
'BK1302':'313',
'BK1303':'313',
'BK1391':'313',
'BK1401':'314',
'BK1402':'314',
'BK1403':'314',
'BK1501':'315',
'BK1502':'315',
'BK1503':'315',
'BK1601':'316',
'BK1602':'316',
'BK1701':'317',
'BK1702':'317',
'BK1703':'317',
'BK1704':'317',
'BK1771':'317',
'BK1801':'318',
'BK1802':'318',
'BK1803':'318',
'BK1891':'318',
'BK1892':'318',
'BK1893':'318',
'BK5591':'355',
'BK5691':'356',
'BK5692':'356',
'BK5693':'356',
'BX0101':'201',
'BX0102':'201',
'BX0201':'202',
'BX0202':'202',
'BX0291':'202',
'BX0301':'203',
'BX0302':'203',
'BX0303':'203',
'BX0391':'203',
'BX0401':'204',
'BX0402':'204',
'BX0403':'204',
'BX0491':'204',
'BX0492':'204',
'BX0501':'205',
'BX0502':'205',
'BX0503':'205',
'BX0601':'206',
'BX0602':'206',
'BX0603':'206',
'BX0701':'207',
'BX0702':'207',
'BX0703':'207',
'BX0801':'208',
'BX0802':'208',
'BX0803':'208',
'BX0901':'209',
'BX0902':'209',
'BX0903':'209',
'BX0904':'209',
'BX0991':'209',
'BX1001':'210',
'BX1002':'210',
'BX1003':'210',
'BX1004':'210',
'BX1071':'210',
'BX1091':'210',
'BX1101':'211',
'BX1102':'211',
'BX1103':'211',
'BX1104':'211',
'BX1161':'211',
'BX1201':'212',
'BX1202':'212',
'BX1203':'212',
'BX1271':'212',
'BX2691':'226',
'BX2791':'227',
'BX2891':'228',
'MN0101':'101',
'MN0102':'101',
'MN0191':'101',
'MN0201':'102',
'MN0202':'102',
'MN0203':'102',
'MN0301':'103',
'MN0302':'103',
'MN0303':'103',
'MN0401':'104',
'MN0402':'104',
'MN0501':'105',
'MN0502':'105',
'MN0601':'106',
'MN0602':'106',
'MN0603':'106',
'MN0604':'106',
'MN0661':'106',
'MN0701':'107',
'MN0702':'107',
'MN0703':'107',
'MN0801':'108',
'MN0802':'108',
'MN0803':'108',
'MN0901':'109',
'MN0902':'109',
'MN0903':'109',
'MN1001':'110',
'MN1002':'110',
'MN1101':'111',
'MN1102':'111',
'MN1191':'111',
'MN1201':'112',
'MN1202':'112',
'MN1203':'112',
'MN1291':'112',
'MN1292':'112',
'MN6491':'164',
'QN0101':'401',
'QN0102':'401',
'QN0103':'401',
'QN0104':'401',
'QN0105':'401',
'QN0151':'401',
'QN0161':'401',
'QN0171':'401',
'QN0191':'401',
'QN0201':'402',
'QN0202':'402',
'QN0203':'402',
'QN0261':'402',
'QN0271':'402',
'QN0301':'403',
'QN0302':'403',
'QN0303':'403',
'QN0401':'404',
'QN0402':'404',
'QN0501':'405',
'QN0502':'405',
'QN0503':'405',
'QN0504':'405',
'QN0571':'405',
'QN0572':'405',
'QN0573':'405',
'QN0574':'405',
'QN0601':'406',
'QN0602':'406',
'QN0701':'407',
'QN0702':'407',
'QN0703':'407',
'QN0704':'407',
'QN0705':'407',
'QN0706':'407',
'QN0707':'407',
'QN0761':'407',
'QN0791':'407',
'QN0801':'408',
'QN0802':'408',
'QN0803':'408',
'QN0804':'408',
'QN0805':'408',
'QN0871':'408',
'QN0891':'408',
'QN0901':'409',
'QN0902':'409',
'QN0903':'409',
'QN0904':'409',
'QN0905':'409',
'QN1001':'410',
'QN1002':'410',
'QN1003':'410',
'QN1091':'410',
'QN1101':'411',
'QN1102':'411',
'QN1103':'411',
'QN1104':'411',
'QN1191':'411',
'QN1201':'412',
'QN1202':'412',
'QN1203':'412',
'QN1204':'412',
'QN1205':'412',
'QN1206':'412',
'QN1301':'413',
'QN1302':'413',
'QN1303':'413',
'QN1304':'413',
'QN1305':'413',
'QN1306':'413',
'QN1307':'413',
'QN1371':'413',
'QN1401':'414',
'QN1402':'414',
'QN1403':'414',
'QN1491':'414',
'QN8081':'480',
'QN8191':'481',
'QN8291':'482',
'QN8381':'483',
'QN8491':'484',
'QN8492':'484',
'SI0101':'501',
'SI0102':'501',
'SI0103':'501',
'SI0104':'501',
'SI0105':'501',
'SI0106':'501',
'SI0107':'501',
'SI0191':'501',
'SI0201':'502',
'SI0202':'502',
'SI0203':'502',
'SI0204':'502',
'SI0291':'502',
'SI0301':'503',
'SI0302':'503',
'SI0303':'503',
'SI0304':'503',
'SI0305':'503',
'SI0391':'503',
'SI9561':'595',
'SI9591':'595',
'SI9592':'595',
'SI9593':'595',
}

export function crosswalkPUMAtoCD(puma: string) {
    //console.log("081624 puma", puma);
    //console.log("081624 PUMA_to_CD", PUMA_to_CD);
    //console.log("081624 PUMA_to_CD[puma]", PUMA_to_CD[puma]);
    //console.log("081624 CD_to_PUMA", CD_to_PUMA);
    return PUMA_to_CD[puma];
  }
export function crosswalkNTAtoCD(nta: string) {
    //console.log("111124 nta", nta);
    //console.log("081624 PUMA_to_CD", PUMA_to_CD);
    //console.log("081624 PUMA_to_CD[puma]", PUMA_to_CD[puma]);
    //console.log("081624 CD_to_PUMA", CD_to_PUMA);
    return NTA_to_CD[nta];
  }