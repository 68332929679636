import React, { useState, useEffect, FC } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import { feature } from 'topojson-client';
import 'leaflet/dist/leaflet.css';
import { GeoJsonObject, FeatureCollection } from 'geojson';
import { useData } from '../../../data/DataContext';
import Colors from '../../utilities/Colors'
import { onHoverHistogram, onClickHistogram, useGlobalHoveredId, useGlobalSelectedId, useGlobalActiveGeography, useGlobalActiveIndicator , useGlobalBivariateIndicator, useGlobalBivariateOverRide } from '../../../data/StatusStore';

interface DataItem {
    id: string;
    value: any;
}

interface ChoroplethLayerProps {
    _dataArray: any;
    _keyIndexDataArray: any;
    dataJSON: any;
    dataMeta: any;
    activeGeography: string;
    //activeIndicator: string;
    chartId: string;
    mapType: string;
    //onHover: (e: any) => void;
    //onClick: (e: any) => void;
    //hoveredId: string | null;
    //selectedId: string | null;
}

export const ChoroplethLayer: FC<ChoroplethLayerProps> = (
    { _dataArray, _keyIndexDataArray, dataJSON, dataMeta, activeGeography, /*activeIndicator,*/ chartId, mapType,
        /*, onHover, onClick, hoveredId, selectedId*/}
) => {
    const hoveredId = useGlobalHoveredId();
    const selectedId = useGlobalSelectedId();
    const activeIndicator = useGlobalActiveIndicator();
    const bivariateIndicator = useGlobalBivariateIndicator();
    const bivariateOverRide = useGlobalBivariateOverRide();
    //const activeGeography = useGlobalActiveGeography();

    const [activeGeoJSON, setActiveGeoJSON] = useState<FeatureCollection | null>(null);
    const [mapId, setMapId] = useState<number>(0);
    //const [maxValue, setActiveGeoJSON] = useState<FeatureCollection | null>(null);

    //let bivariateIndicator = "age_pyramid_total";

    const { 
        geoJsonDataPUMA, geoJsonDataTract, geoJsonDataNTA, geoJsonDataBoro,
        isLoadingTopology, 
        error 
    } = useData();

    useEffect(() => {
        // nothing for now
    }, []);

    useEffect(() => {
        console.log("D041824 _keyIndexDataArray", _keyIndexDataArray);
        console.log("D041824 _dataArray", _dataArray);
        console.log("I100524 MAP -- activeIndicator", activeIndicator);
        console.log("I100524 MAP -- bivariateIndicator", bivariateIndicator);
        console.log("D041824 _dataArray[activeIndicator]", _dataArray[activeIndicator]);
        console.log("D041824 dataMeta", dataMeta);
        console.log("I100524 MAP -- activeGeography", activeGeography);
        if (_dataArray && _keyIndexDataArray && dataMeta) {
            const dataArray = _dataArray[activeIndicator];
            const keyIndexDataArray = _keyIndexDataArray[activeIndicator];
            let dataArray2:any = null;
            let keyIndexDataArray2:any = null;
            if (mapType === "bivariate - standard" || mapType === "bivariate - inverted") {
                dataArray2 = _dataArray[bivariateIndicator];
                keyIndexDataArray2 = _keyIndexDataArray[bivariateIndicator];
            };
            console.log("D041824 keyIndexDataArray", keyIndexDataArray);
            console.log("D041824 dataArray", dataArray);
            console.log("D041824 dataMeta", dataMeta);
            if (keyIndexDataArray && dataArray && dataMeta) {
                let tempGeoJSON = geoJsonDataTract;
                if (activeGeography === "PUMA" || activeGeography === "CD") {
                    tempGeoJSON = geoJsonDataPUMA;
                }else if (activeGeography === "Tract") {
                    tempGeoJSON = geoJsonDataTract;
                }else if (activeGeography === "NTA") {
                    tempGeoJSON = geoJsonDataNTA;
                }else if (activeGeography === "Boro") {
                    tempGeoJSON = geoJsonDataBoro;
                }
                if (tempGeoJSON){
                    tempGeoJSON.features.forEach((feature: any) => {
                        //console.log("D041824 feature", feature);
                        if (keyIndexDataArray[feature.properties.GEOID20]){
                            feature.properties["value"] = keyIndexDataArray[feature.properties.GEOID20]?.value;
                            feature.properties["index"] = keyIndexDataArray[feature.properties.GEOID20]?.index;
                            if (mapType === "bivariate - standard") {
                                feature.properties["color"] = Colors.getStandardBivariateColor(
                                    keyIndexDataArray[feature.properties.GEOID20].index,
                                    dataMeta.na_count[activeIndicator],
                                    dataArray.length,
                                    keyIndexDataArray2[feature.properties.GEOID20].index,
                                    dataMeta.na_count[bivariateIndicator],
                                    dataArray2.length,
                                );
                            } else if (mapType === "bivariate - inverted") {
                                feature.properties["color"] = Colors.getInvertedBivariateColor(
                                    keyIndexDataArray[feature.properties.GEOID20].index,
                                    dataMeta.na_count[activeIndicator],
                                    dataArray.length,
                                    keyIndexDataArray2[feature.properties.GEOID20].index,
                                    dataMeta.na_count[bivariateIndicator],
                                    dataArray2.length,
                                    bivariateOverRide, 
                                    keyIndexDataArray2[feature.properties.GEOID20].value, 
                                    keyIndexDataArray[feature.properties.GEOID20].value 
                                );
                            } else {
                                feature.properties["color"] = Colors.getColorQuintile(keyIndexDataArray[feature.properties.GEOID20].index, dataMeta.na_count[activeIndicator], dataArray.length, dataMeta["Module Color"][activeIndicator]);
                            }
                        }
                    });
                }
                setActiveGeoJSON(tempGeoJSON);
                setMapId(mapId + 1);
            }
        }
    }, [activeIndicator, bivariateIndicator, bivariateOverRide /*activeGeography,*/, _keyIndexDataArray, mapType, _dataArray]);

    useEffect(() => {
        console.log("activeGeoJSON",activeGeoJSON);
    }, [activeGeoJSON]);

    const onEachFeature = (feature: any, layer: L.Layer) => {
        /*if (feature.properties.GEOID20 === selectedId) {
            // Bring the selected polygon to the front
            (layer as L.Polygon).bringToFront();
        } else {
            // Bring the other polygons to the back to avoid overlap
            (layer as L.Polygon).bringToBack();
        }*/
        layer.on('mouseover', () => {
            //console.log("B093024 feature?.properties", feature?.properties)
            onHoverHistogram(feature?.properties.GEOID20 ? feature.properties.GEOID20 : null);
        });
        layer.on('mouseout', () => {
            //console.log("B093024 feature?.properties", feature?.properties)
            onHoverHistogram(null);
        });
        layer.on('click', () => {
            //console.log("B093024 feature?.properties", feature?.properties)
            //console.log("101224 feature", feature)
            onClickHistogram(feature?.properties.GEOID20 ? feature.properties.GEOID20 : null);
        });
    };

    const style = (feature: any) => {
        //let color = getColorQuintile()
        //console.log("C093024 feature?.properties", feature?.properties)
        //console.log("C093024 hoveredId", hoveredId)
        //console.log("C093024 selectedId", selectedId)
        return {
            //color: '#ffffff',
            //weight: 0.15,
            weight: feature.properties.GEOID20 == selectedId
                ? 2 
                : 0.15,
            color: feature.properties.GEOID20 == selectedId
                ? Colors.highlightSelect 
                : '#ffffff',
            fillColor: feature.properties.GEOID20 == hoveredId 
                ? Colors.highlightHover 
                : feature.properties.color,
                /* FOR FULL COLOR (BLACK) ON SELECTION
                : feature.properties.GEOID20 == selectedId 
                    ? Colors.highlightSelect 
                    : feature.properties.color, // Change fill color based on hover/selcetion*/
            fillOpacity: 0.75,
            smoothFactor:0,
        };
    };

    return activeGeoJSON ? (
        <GeoJSON
            key={`map${mapId}`}
            data={activeGeoJSON}
            style={style}
            onEachFeature={onEachFeature}
        />
    ) : null;
};